import { SelectChangeEvent } from "@mui/material/Select";
import {
  ChangeEvent,
  MouseEvent,
  ReactNode,
  memo,
  useMemo,
  useRef,
  useState,
  useDeferredValue,
  useEffect,
} from "react";
import { GroupedVirtuoso, GroupedVirtuosoHandle } from "react-virtuoso";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { selectSiteList } from "../../../../features/auth/authSlice";
import { selectStoreToFocus } from "../../../../features/view/viewSlice";
import {
  SiteType,
  SitesGroupedByOperator,
  useGetSitesQuery,
} from "../../../../features/sites/sitesAPI";
import useCheckExpiredStores from "../../../../hooks/appHooks/useCheckExpiredStores";
import Loading from "../../../Utility/Loading";
import SiteMenuHeader from "./SiteListHeader/SiteListHeader";
import Site from "./Site/Site";
import { useDebounce } from "../../../../hooks/useDebounce";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  selectFilteredSiteOperators,
  toggleFilterOperatorThunk,
  setOperatorsFilterThunk,
  resetOperatorsFilterThunk,
} from "../../../../features/sites/sitesSlice";

interface Props {
  // entered: boolean;
  handleStoresDrawerToggle: () => void;
  isMobileScreen: boolean;
}

interface EmptyListProps {
  isScrolling: boolean;
}

export const EmptyList = memo(({ isScrolling }: EmptyListProps) => {
  return (
    <Site
      site={{
        active_alarm_count: 0,
        critical_active_alarm_count: 0,
        cloud_start_date: null,
        store_name: "No Sites",
        store_num: 0,
        store_city: "",
        store_state: "",
        latitude: 0,
        longitude: 0,
        offline: true,
        // mac_address: null,
        cloud_users_limit: 10,
        cloud_end_date: null,
        floor_plan_enabled: false,
        leo_installed: false,
        leo_version: "",
        store_address: "",
        store_operator: "HL",
        terminal_mode_enabled: false,
        controllers: [],
        cloud_contacts: [],
        time_zone: null,
        controllers_in_alarm: null,
        chain_id: 0,
        ingest_data: false,
        mac_address: null,
        store_order: 0,
        mqtt_path: "",
        voice_sms_subscription: null,
        sms_receivers_limit: 0,
        voip_receivers_limit: 0,
      }}
      index={0}
      isScrolling={isScrolling}
      isVisible
      showGroup
      setShowGroup={(show: boolean) => console.log(show)}
      isMobileScreen={false}
    />
  );
});

const SiteList = memo(({ handleStoresDrawerToggle, isMobileScreen }: Props) => {
  const dispatch = useAppDispatch();
  const store_list = useAppSelector(selectSiteList);
  const siteToFocus = useAppSelector(selectStoreToFocus);

  const [showSiteOperatorFilter, setShowSiteOperatorFilter] = useState(false);

  const filteredSiteOperators = useAppSelector(selectFilteredSiteOperators);

  const handleFilterSiteOperator = (chain: string) => {
    dispatch(toggleFilterOperatorThunk(chain));
  };
  const handleFilterAllSiteOperators = (chains: string[]) => {
    dispatch(setOperatorsFilterThunk(chains));
  };
  const handleUnfilterAllSiteOperators = () => {
    dispatch(resetOperatorsFilterThunk());
  };

  const filteredSiteOperatorsDeferred = useDeferredValue(filteredSiteOperators);

  const [isScrolling, setIsScrolling] = useState(false);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC" | null>(
    "ASC",
  );

  const [showFilter, setShowFilter] = useState(false);

  const isFilterMenuOpen = Boolean(showMenu && !showFilter);

  const [filterState, setFilterState] = useState({
    filterBy: "store_name",
    filterString: "",
  });

  const filterString = useDebounce(filterState.filterString); // not correct usage needs a primitive value
  const filterBy = useDebounce(filterState.filterBy);

  const setFilterBy = (event: SelectChangeEvent<string>, _child: ReactNode) => {
    setFilterState((prev) => ({ ...prev, filterBy: event.target.value }));
  };
  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterState((prev) => ({ ...prev, filterString: e.target.value }));
  };

  const handleShowFilter = () => setShowFilter(true);
  const handleHideFilter = () => {
    if (showMenu) {
      setShowMenu(false);
    }
    setShowFilter(false);
  };

  const handleSetSortASC = () => setSortDirection("ASC");
  const handleSetSortDESC = () => setSortDirection("DESC");

  const handleFilterMenuClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (menuAnchor === null) {
      setMenuAnchor(e.currentTarget);
    }
    setShowMenu(true);
  };
  const handleFilterMenuHide = () => setShowMenu(false);

  const { data, isLoading } = useGetSitesQuery(
    { store_list },
    { refetchOnMountOrArgChange: 300, pollingInterval: 600000 },
  );

  // handles showing a user on login if they have expired stores.
  // occurs daily
  useCheckExpiredStores(data);

  const leoInstalledSites = useMemo(() => {
    if (data) {
      return data.filter((site) => site.leo_installed);
    }
    return [];
  }, [data]);

  const siteOperators: string[] = useMemo(() => {
    const chains: string[] = [];
    if (data) {
      for (const site of data) {
        let group = site.store_operator;
        if (group === "") group = "No Group";

        if (!chains.includes(group)) {
          chains.push(group);
        }
      }
    }
    return chains.sort();
  }, [data]);

  const groupedSites: SitesGroupedByOperator = useMemo(() => {
    if (leoInstalledSites?.length) {
      const returnData: SitesGroupedByOperator = [];
      for (const site of leoInstalledSites) {
        let group = site.store_operator;
        if (group === "") group = "No Group";

        const hidden = filteredSiteOperatorsDeferred.includes(group);
        const exists = returnData.find((d) => d.group === group);

        if (!hidden) {
          if (exists) {
            exists.sites.push(site);
          } else {
            returnData.push({
              group,
              sites: [site],
            });
          }
        }
      }
      return returnData.sort((a, b) =>
        a.group
          .toLowerCase()
          .localeCompare(b.group.toLowerCase(), undefined, { numeric: true }),
      );
    }
    return [];
  }, [leoInstalledSites, filteredSiteOperatorsDeferred]);

  const filteredGroupedSites: SitesGroupedByOperator = useMemo(() => {
    if (groupedSites) {
      if (filterString === "") {
        if (sortDirection !== null) {
          const dataClone = structuredClone(groupedSites);
          for (const group of dataClone) {
            group.sites = group.sites.sort((a, b) =>
              a.store_name.localeCompare(b.store_name, "en-US", {
                numeric: true,
              }),
            );
            if (sortDirection === "DESC") {
              group.sites.reverse();
            }
          }
          return dataClone;
        } else {
          return groupedSites;
        }
      } else {
        const dataClone = structuredClone(groupedSites);
        if (sortDirection !== null) {
          for (const group of dataClone) {
            group.sites = group.sites
              .filter((val) => {
                switch (filterBy) {
                  case "store_name": {
                    return val.store_name
                      .toLowerCase()
                      .includes(filterString.toLowerCase());
                  }
                  case "chain_name": {
                    return val.store_operator
                      .toLowerCase()
                      .includes(filterString.toLowerCase());
                  }
                }
              })
              .sort((a, b) =>
                a.store_name.localeCompare(b.store_name, "en-US", {
                  numeric: true,
                }),
              );
          }
          const filteredEmptyGroups = dataClone.filter(
            (v) => v.sites.length > 0,
          );
          if (sortDirection === "ASC") {
            return filteredEmptyGroups;
          } else {
            return filteredEmptyGroups.reverse();
          }
        } else {
          for (const group of dataClone) {
            group.sites = group.sites.filter((val) => {
              switch (filterBy) {
                case "store_name": {
                  return val.store_name
                    .toLowerCase()
                    .includes(filterString.toLowerCase());
                }
                case "chain_name": {
                  return val.store_operator
                    .toLowerCase()
                    .includes(filterString.toLowerCase());
                }
              }
            });
          }
          const filteredEmptyGroups = dataClone.filter(
            (v) => v.sites.length > 0,
          );
          return filteredEmptyGroups;
        }
      }
    }

    return [];
  }, [groupedSites, sortDirection, filterString, filterBy]);

  const filteredGroupCounts = useMemo(() => {
    const countsArr: number[] = [];
    for (const group of filteredGroupedSites) {
      countsArr.push(group.sites.length);
    }
    return countsArr;
  }, [filteredGroupedSites]);

  const groupedSitesInOrder = useMemo(() => {
    const sitesArr: SiteType[] = [];
    for (const group of filteredGroupedSites) {
      sitesArr.push(...group.sites);
    }
    return sitesArr;
  }, [filteredGroupedSites]);

  useEffect(() => {
    if (siteToFocus !== null && virtRef.current) {
      const siteIndex = groupedSitesInOrder.findIndex(
        (v) => v.store_num === siteToFocus,
      );
      if (siteIndex > -1) {
        virtRef.current.scrollToIndex(siteIndex);
      }
    }
  }, [siteToFocus, groupedSitesInOrder]);

  const virtRef = useRef<GroupedVirtuosoHandle>(null);

  return (
    <>
      <SiteMenuHeader
        filterState={filterState}
        filterStringDebounced={filterString}
        handleFilterChange={handleFilterChange}
        handleFilterMenuClick={handleFilterMenuClick}
        handleFilterMenuHide={handleFilterMenuHide}
        handleHideFilter={handleHideFilter}
        handleSetSortASC={handleSetSortASC}
        handleSetSortDESC={handleSetSortDESC}
        handleShowFilter={handleShowFilter}
        handleStoresDrawerToggle={handleStoresDrawerToggle}
        isFilterMenuOpen={isFilterMenuOpen}
        menuAnchor={menuAnchor}
        setFilterBy={setFilterBy}
        setFilterState={setFilterState}
        showFilter={showFilter}
        sortDirection={sortDirection}
        showSiteOperatorFilter={showSiteOperatorFilter}
        setShowSiteOperatorFilter={setShowSiteOperatorFilter}
        operators={siteOperators}
        filteredSiteOperators={filteredSiteOperators}
        handleFilterSiteOperator={handleFilterSiteOperator}
        handleFilterAllSiteOperators={handleFilterAllSiteOperators}
        handleUnfilterAllSiteOperators={handleUnfilterAllSiteOperators}
      />

      {isLoading ||
      (data && data?.length > 0 && leoInstalledSites?.length === 0) ? (
        <Loading size={"2rem"} sx={{ marginY: "0.5rem" }} />
      ) : (
        <GroupedVirtuoso
          ref={virtRef}
          height="100%"
          style={{ position: "relative" }}
          isScrolling={setIsScrolling}
          groupCounts={filteredGroupCounts}
          increaseViewportBy={100}
          overscan={10}
          groupContent={(i) => (
            <ListItem
              sx={{
                backgroundColor: (theme) => theme.palette.background.default,
              }}
            >
              <ListItemText primary={filteredGroupedSites[i].group} />
            </ListItem>
          )}
          itemContent={(i, groupI) => {
            const site = groupedSitesInOrder[i];
            const group = filteredGroupedSites[groupI].group;
            return (
              <Site
                site={site}
                index={i}
                isScrolling={isScrolling}
                isVisible={true}
                showGroup={true}
                setShowGroup={() => null}
                key={`${site.store_num}${group}`}
                isMobileScreen={isMobileScreen}
              />
            );
          }}
          defaultItemHeight={56}
          components={{
            EmptyPlaceholder: () => <EmptyList isScrolling={isScrolling} />,
          }}
        />
      )}
    </>
  );
});

export default SiteList;
