import { useEffect, useRef } from "react";
import { DecodedToken } from "../../features/auth/authSlice";
import jwtDecode from "jwt-decode";
import { DateTime } from "luxon";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useRefreshTokenMutation } from "../../features/auth/authAPI";
import { selectAuthToken, logout } from "../../features/auth/authSlice";
import { useSnackbar } from "notistack";
import {
  isFetchBaseQueryError,
  isSerializedError,
} from "../../utils/typeChecks";
import { showSessionExpiredDialog } from "../../features/view/viewSlice";
import { resetHiddenChains } from "../../features/sites/sitesSlice";

const maxTimeout = 2147483647;

const useTokenExpirationHandler = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectAuthToken);

  const interactionRan = useRef(false);

  const [refreshToken] = useRefreshTokenMutation();

  useEffect(() => {
    if (token && token !== "") {
      const decodedToken = jwtDecode(token) as DecodedToken;

      const tokenExpiration = decodedToken.exp;
      const nowSecs = DateTime.now().toUTC().toUnixInteger();

      const diffSeconds = tokenExpiration - nowSecs; // this should be how much longer the token is valid
      const diffMs = diffSeconds * 1000;

      let myExpireTimeout: NodeJS.Timeout | null = null;

      // if the token has more then 5 minutes wait until 5 minutes left
      // if the token expires in 5 minutes addEventListeners to check for interaction
      // on interaction refresh the token
      // we could move these listeners into the timeout
      // I also need to make sure the value of timeoutTime is not over the 32bit integer limit
      let timeoutTime = diffMs > 300000 ? diffMs - 300000 : 0;
      if (timeoutTime > maxTimeout) {
        timeoutTime = maxTimeout - 1;
      }

      // set a timeout that runs 5 minutes before expiration
      // check the time of last user interaction
      const handleInteraction = async () => {
        // need to make sure i only call refreshTokenOnce on interaction
        if (!interactionRan.current) {
          interactionRan.current = true;
          try {
            console.log("%cRefreshing token", "color: orange");
            await refreshToken().unwrap(); // no argument needed
          } catch (err: unknown) {
            if (isFetchBaseQueryError(err)) {
              if (typeof err.status === "number") {
                if (err.status === 401) {
                  enqueueSnackbar({
                    message:
                      "A Unknown Error occured while refreshing the Session",
                    variant: "error",
                    title: "Error",
                  });
                } else {
                  enqueueSnackbar({
                    message: `${err.data}`,
                    variant: "error",
                    title: `${err.status} Error`,
                  });
                }
              } else {
                enqueueSnackbar({
                  message: `${err.data}`,
                  variant: "error",
                  title: `${err.status} Error`,
                });
              }
            } else if (isSerializedError(err)) {
              enqueueSnackbar({
                message: `Code: ${err.code ?? "N/A"} Name: ${
                  err.name ?? "N/A"
                } Message: ${err.message ?? "unknown?"}`,
                variant: "error",
                title: "Serialized Error",
              });
            } else {
              enqueueSnackbar({
                message: `An unknown error occured`,
                variant: "error",
                title: "Error",
              });
            }
          }
        }
      };

      const handleAwaitTokenExpiration = () => {
        const nowSecs = DateTime.now().toUTC().toUnixInteger();
        const diffSeconds = tokenExpiration - nowSecs; // this should be how much longer the token is valid
        const diffMs = diffSeconds * 1000;
        const handleLogout = () => {
          dispatch(showSessionExpiredDialog(true));
          dispatch(logout());
          dispatch(resetHiddenChains());
        };

        console.log({ diffSeconds, tokenExpiration, nowSecs });
        if (diffMs >= maxTimeout) return;

        console.info(`handleAwaitTokenExpiration -> diffMS: ${diffMs}`);
        if (diffMs > 0 && diffMs < maxTimeout) {
          console.log(`setTimeout to logout: ${diffMs} -> token expires in`);
          myExpireTimeout = setTimeout(() => {
            // logout
            handleLogout();
          }, diffMs);
        } else {
          // logout
          handleLogout();
        }
      };

      // need to make sure we don't remove these listeners accidently in the last 5 minutes
      const myTimeout = setTimeout(() => {
        console.info("%cuserInteraction listeners set!", "color: yellow");
        window.addEventListener("click", handleInteraction);
        window.addEventListener("mousemove", handleInteraction);
        window.addEventListener("scroll", handleInteraction);
        window.addEventListener("touchstart", handleInteraction);
        window.addEventListener("keydown", handleInteraction);
        handleAwaitTokenExpiration();
      }, timeoutTime);

      return () => {
        console.info("%ccleared userInteraction listeners!", "color: red");
        window.removeEventListener("click", handleInteraction);
        window.removeEventListener("mousemove", handleInteraction);
        window.removeEventListener("scroll", handleInteraction);
        window.removeEventListener("touchstart", handleInteraction);
        window.removeEventListener("keydown", handleInteraction);
        clearTimeout(myTimeout);
        if (myExpireTimeout) clearTimeout(myExpireTimeout);
      };
    }
  }, [token, dispatch, refreshToken, enqueueSnackbar]);
};

export default useTokenExpirationHandler;
