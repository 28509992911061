import { ReactNode, ChangeEvent, Dispatch, SetStateAction, memo } from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";

type Props = {
  showFilter: boolean;
  menuAnchor: HTMLElement | null;
  handleHideFilter: () => void;
  filterState: { filterBy: string; filterString: string };
  filterStringDebounced: string;
  setFilterBy: (e: SelectChangeEvent<string>, child: ReactNode) => void;
  handleFilterChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setFilterState: Dispatch<
    SetStateAction<{
      filterBy: string;
      filterString: string;
    }>
  >;
};

const SiteFilter = ({
  showFilter,
  menuAnchor,
  handleHideFilter,
  filterState,
  filterStringDebounced,
  setFilterBy,
  handleFilterChange,
  setFilterState,
}: Props) => {
  const handleClear = () => {
    setFilterState((prev) => ({ ...prev, filterString: "" }));
    handleHideFilter();
  };

  return (
    <Popover
      open={showFilter}
      anchorEl={menuAnchor}
      onClose={handleHideFilter}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Stack
        padding={2}
        columnGap={1}
        rowGap={1}
        direction={{ sm: "column", md: "row" }}
        alignItems={{ sm: "flex-start", md: "stretch" }}
      >
        <Button variant="contained" color="error" onClick={handleClear}>
          Clear
        </Button>
        <FormControl>
          <InputLabel id="filter-by-label">Filter By</InputLabel>
          <Select
            value={filterState.filterBy}
            onChange={setFilterBy}
            labelId="filter-by-label"
            id="filter-by-select"
            label="Filter By"
            sx={{ maxWidth: 200 }}
          >
            <MenuItem value="store_name">Site Name</MenuItem>
            <MenuItem value="store_marker_id">Store Marker ID</MenuItem>
            <MenuItem value="chain_name">Chain Name</MenuItem>
            <MenuItem value="chain_id">Chain ID</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Value"
          value={filterState.filterString}
          onChange={handleFilterChange}
          autoFocus
          sx={{ width: 190 }}
          InputProps={{
            endAdornment: filterState.filterString !==
              filterStringDebounced && (
              <InputAdornment position="end">
                <AutorenewOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />

        <Button size="large" variant="contained" onClick={handleHideFilter}>
          Filter
        </Button>
      </Stack>
    </Popover>
  );
};

export default memo(SiteFilter);
