import {
  Dispatch,
  SetStateAction,
  useMemo,
  useDeferredValue,
  useState,
  ChangeEventHandler,
  MouseEvent,
} from "react";
import Box from "@mui/material/Box";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";

type Props = {
  showSiteOperatorFilter: boolean;
  setShowSiteOperatorFilter: Dispatch<SetStateAction<boolean>>;
  operators: string[];
  filteredSiteOperators: string[];
  handleFilterSiteOperator: (operator: string) => void;
  handleFilterAllSiteOperators: (operators: string[]) => void;
  handleUnfilterAllSiteOperators: () => void;
};

const SiteOperatorFilter = ({
  showSiteOperatorFilter,
  setShowSiteOperatorFilter,
  operators,
  filteredSiteOperators,
  handleFilterSiteOperator,
  handleFilterAllSiteOperators,
  handleUnfilterAllSiteOperators,
}: Props) => {
  const [filter, setFilter] = useState("");
  const filterDeferred = useDeferredValue(filter);

  const handleFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilter(e.target.value);
  };

  const [showDialog, setShowDialog] = useState(false);
  const handleShowDialog = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDialog(true);
  };
  const handleHideDialog = () => {
    setShowDialog(false);
    if (filter !== "") {
      setFilter("");
    }
  };

  const dialogAvailable = useMemo(() => {
    return operators.length >= 5;
  }, [operators.length]);

  // also make sure no filters are being applied if isHidden
  const isHidden = useMemo(() => {
    return operators.length <= 1;
  }, [operators]);

  const handleChainFilterClick = () => {
    setShowSiteOperatorFilter(!showSiteOperatorFilter);
  };

  const filteredChains = useMemo(() => {
    if (filterDeferred === "") {
      return operators;
    } else {
      return operators.filter((c) =>
        c.toLowerCase().includes(filterDeferred.toLowerCase()),
      );
    }
  }, [operators, filterDeferred]);

  const handleHideAll = () => {
    handleFilterAllSiteOperators(operators);
  };

  const handleShowAll = () => {
    handleUnfilterAllSiteOperators();
  };

  const isAllHidden = useMemo(() => {
    const isEqual = operators.every((val) =>
      filteredSiteOperators.includes(val),
    );
    const isEqual2 =
      filteredSiteOperators.length === operators.length && isEqual;
    return isEqual2;
  }, [filteredSiteOperators, operators]);
  const isAllShown = useMemo(() => {
    return filteredSiteOperators.length === 0;
  }, [filteredSiteOperators]);

  // if operators.length === 1 dont show
  // and apply no filter
  // need to apply logic for this ^^^

  return (
    <Box
      sx={{
        postion: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",

        ...(isHidden && {
          display: "none",
        }),
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        columnGap={0.5}
        onClick={handleChainFilterClick}
      >
        <Typography>
          Filter <span>({filteredSiteOperators.length})</span>
        </Typography>
        <IconButton>
          {showSiteOperatorFilter ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        {dialogAvailable && (
          <IconButton
            sx={{ position: "absolute", right: (theme) => theme.spacing(1) }}
            onClick={handleShowDialog}
          >
            <LaunchOutlinedIcon />
          </IconButton>
        )}
      </Box>

      <Collapse in={showSiteOperatorFilter}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            maxHeight: 150,
            overflowY: "auto",
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          {operators.map((chain) => {
            const isHidden = filteredSiteOperators.includes(chain);
            return (
              <FormControlLabel
                title="Click to toggle a groups visibility"
                key={chain}
                label={chain}
                labelPlacement="end"
                sx={{ marginLeft: 3 }}
                control={
                  <Checkbox
                    checked={isHidden}
                    icon={<VisibilityIcon color="success" />}
                    checkedIcon={<VisibilityOffIcon color="error" />}
                    onClick={() => handleFilterSiteOperator(chain)}
                  />
                }
              />
            );
          })}
        </Box>
      </Collapse>
      <Dialog
        maxWidth="md"
        fullWidth
        open={showDialog}
        onClose={handleHideDialog}
      >
        <DialogTitle sx={{ display: "flex", columnGap: 1 }}>
          Filter <span>({filteredSiteOperators.length})</span>
          <TextField
            onChange={handleFilterChange}
            value={filter}
            placeholder="search..."
            label="Search"
            size="small"
            sx={{ marginLeft: "auto" }}
          />
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          <Grid container spacing={1}>
            {filteredChains.map((chain) => {
              const isHidden = filteredSiteOperators.includes(chain);
              return (
                <Grid key={chain} xs={6} sm={4} md={2}>
                  <FormControlLabel
                    title="Click to toggle a groups visibility"
                    label={chain}
                    labelPlacement="end"
                    sx={{ flexBasis: "20%", marginLeft: 0, marginRight: 0 }}
                    control={
                      <Checkbox
                        checked={isHidden}
                        icon={<VisibilityIcon color="success" />}
                        checkedIcon={<VisibilityOffIcon color="error" />}
                        onClick={() => handleFilterSiteOperator(chain)}
                      />
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonGroup
            sx={{ marginRight: "auto" }}
            variant="contained"
            color="info"
          >
            <Button disabled={isAllHidden} onClick={handleHideAll}>
              Hide All
            </Button>
            <Button disabled={isAllShown} onClick={handleShowAll}>
              Show All
            </Button>
          </ButtonGroup>

          <Button variant="contained" onClick={handleHideDialog}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SiteOperatorFilter;
