import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import {
  ChangeEventHandler,
  FormEventHandler,
  useState,
  useEffect,
} from "react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import PasswordInput from "../../../components/Inputs/PasswordInput";
import TransparentTextField from "../../../components/Inputs/TransparentTextField";
import { useForgotMyPasswordResetMutation } from "../../../features/users/usersAPI";
import useCheckIfPasswordsMatch from "../../../hooks/useCheckIfPasswordsMatch";
import FormHelperText from "@mui/material/FormHelperText";
import { validPasswordRegex } from "../../../constants/constants";

const ForgotPasswordReset = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();

  const [username, setUsername] = useState(searchParams.get("username") ?? "");
  const [recoveryPassword, setRecoveryPassword] = useState(
    searchParams.get("recoveryPassword") ?? "",
  );

  useEffect(() => {
    if (
      searchParams.get("username") !== null ||
      searchParams.get("recoveryPassword") !== null
    ) {
      setSearchParams("");
    }
  }, [searchParams, setSearchParams]);

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordCheck, setNewPasswordCheck] = useState("");

  const { passwordsDontMatch } = useCheckIfPasswordsMatch(
    newPassword,
    newPasswordCheck,
  );

  const [resetMyPassword, { isLoading }] = useForgotMyPasswordResetMutation();

  const handleTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "EmailAddress":
        setUsername(value);
        break;
      case "RecoveryPassword":
        setRecoveryPassword(value);
        break;
      case "NewPassword":
        setNewPassword(value);
        break;
      case "NewPasswordCheck":
        setNewPasswordCheck(value);
        break;
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (
      e.target instanceof HTMLFormElement &&
      e.target.reportValidity() &&
      newPassword === newPasswordCheck
    ) {
      if (newPassword.match(validPasswordRegex) === null) {
        enqueueSnackbar(
          "Password must be at least 8 characters long and contain at least one uppercase letter and one number",
          { variant: "error", autoHideDuration: 12000 },
        );
        return;
      }

      try {
        const res = await resetMyPassword({
          username,
          recovery_password: recoveryPassword,
          password: newPassword,
        }).unwrap();

        if (res.status === "success") {
          enqueueSnackbar("Password Reset!", { variant: "info" });
          navigate("/login");
        }
      } catch (err) {
        enqueueSnackbar("Unknown Error Occured!", { variant: "error" });
      }
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        mt: 8,
        mb: 1,
        position: "relative",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          textAlign={"center"}
          component="h1"
          variant="h4"
          marginBottom={1}
        >
          Reset Forgotten Password
        </Typography>

        <Typography textAlign={"center"} variant="subtitle2">
          Enter your email address and the recovery password you received
        </Typography>

        <Box
          component="form"
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            rowGap: 1,
          }}
          onSubmit={handleSubmit}
        >
          <TransparentTextField
            size="small"
            required
            fullWidth
            label="Email"
            name="EmailAddress"
            autoComplete="email"
            autoFocus
            value={username}
            onChange={handleTextChange}
          />

          <PasswordInput
            size="small"
            required
            label="Recovery Password"
            name="RecoveryPassword"
            value={recoveryPassword}
            onChange={handleTextChange}
            fullWidth
            autoComplete="one-time-code"
          />

          <PasswordInput
            size="small"
            required
            label="New Password"
            name="NewPassword"
            value={newPassword}
            onChange={handleTextChange}
            error={passwordsDontMatch}
            fullWidth
            sx={{ mt: 2 }}
            autoComplete="new-password"
          />

          <PasswordInput
            size="small"
            required
            label="Confirm New Password"
            name="NewPasswordCheck"
            value={newPasswordCheck}
            onChange={handleTextChange}
            error={passwordsDontMatch}
            autoComplete="new-password"
            fullWidth
            helperText={
              passwordsDontMatch ? "Passwords must match!" : undefined
            }
          />

          <FormHelperText sx={{ marginX: 1.5 }}>
            Your password must be at least 8 characters long and include at
            least one capital letter and one number.
          </FormHelperText>

          <LoadingButton
            loading={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </LoadingButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "0.1rem",
            }}
          >
            <Link
              component={RouterLink}
              to="/login"
              sx={{
                color: (theme) => theme.palette.secondary.main,
              }}
              state={{
                redirectTo: location?.state?.redirectTo,
              }}
              aria-label="Navigate to the Login Page"
            >
              Remember your password?
            </Link>
            <Link
              color="secondary"
              href="https://www.hunterliberty.com/contact-us-2"
              data-testid="contactLink"
            >
              Contact Us
            </Link>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default ForgotPasswordReset;
