import DarkModeIcon from "@mui/icons-material/DarkMode";
import HomeIcon from "@mui/icons-material/Home";
import LightModeIcon from "@mui/icons-material/LightMode";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { useCallback, useMemo, useEffect } from "react";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectTheme, toggleMode } from "../../../features/view/viewSlice";
import { styled } from "@mui/material/styles";
import ToggleMenu from "../Menu/ToggleMenu";
import SyncIcon from "@mui/icons-material/Sync";
import { useLazyUpdateTokenQuery } from "../../../features/auth/authAPI";
import { useSnackbar } from "notistack";
import { selectIsAuthenticated } from "../../../features/auth/authSlice";

const AppBarHeader = styled((props: TypographyProps) => (
  <Typography noWrap variant="h5" component="h1" {...props} />
))<TypographyProps>(() => ({}));

export type StoreParamsType = {
  storeId: string;
  storeNameAndLocation: string;
};
export type SiteParamsType = {
  siteId: string;
  siteNameAndLocation: string;
};
export type StoreControllerParamsType = StoreParamsType & {
  controllerName: string;
};

export type StoreControllerTypeParamsType = StoreParamsType & {
  controllerType: string;
};
export type StoreControllerTypeGraphParamsType =
  StoreControllerTypeParamsType & {
    controllerName: string;
  };

export type GraphApplicationParamsType = StoreControllerParamsType & {
  applicationName: string;
};

function isStoreParams(val: unknown): val is StoreParamsType {
  return (
    typeof val === "object" && val !== null && "storeNameAndLocation" in val
  );
}

function isSiteParams(val: unknown): val is SiteParamsType {
  return (
    typeof val === "object" && val !== null && "siteNameAndLocation" in val
  );
}

function isStoreControllerParams(
  val: unknown,
): val is StoreControllerParamsType {
  return (
    typeof val === "object" &&
    val !== null &&
    "storeNameAndLocation" in val &&
    "controllerName" in val
  );
}
function isStoreControllerTypeParams(
  val: unknown,
): val is StoreControllerTypeParamsType {
  return (
    typeof val === "object" &&
    val !== null &&
    "storeNameAndLocation" in val &&
    "controllerType" in val
  );
}

function isStoreControllerTypeGraphParams(
  val: unknown,
): val is StoreControllerTypeGraphParamsType {
  return (
    typeof val === "object" &&
    val !== null &&
    "storeNameAndLocation" in val &&
    "controllerType" in val &&
    "controllerName" in val
  );
}

function isStoreControllerApplicationGraphParams(
  val: unknown,
): val is GraphApplicationParamsType {
  return (
    typeof val === "object" &&
    val !== null &&
    "storeNameAndLocation" in val &&
    "controllerName" in val &&
    "applicationName" in val
  );
}

const updateTokenInterval = 1000 * 60 * 9; // every nine minutes

const Header = () => {
  const { enqueueSnackbar } = useSnackbar();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const colorMode = useAppSelector(selectTheme);
  const dispatch = useAppDispatch();
  const params = useParams();
  const location = useLocation();

  const pathNames = location.pathname.split("/").filter((x) => x !== "");

  const handleColorModeToggle = () => dispatch(toggleMode());

  const [
    updateToken,
    {
      isLoading: isUpdatingToken,
      isFetching: isFethingUpdatedToken,
      isUninitialized,
    },
  ] = useLazyUpdateTokenQuery({
    pollingInterval: updateTokenInterval,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    skipPollingIfUnfocused: true,
  });

  useEffect(() => {
    let myTo: NodeJS.Timeout | undefined;
    if (isAuthenticated && isUninitialized) {
      myTo = setInterval(() => {
        updateToken();
      }, updateTokenInterval);
    }

    return () => {
      if (myTo) {
        clearInterval(myTo);
      }
    };
  }, [isAuthenticated, isUninitialized, updateToken]);

  const isTokenUpdating = useMemo(() => {
    return isUpdatingToken || isFethingUpdatedToken;
  }, [isUpdatingToken, isFethingUpdatedToken]);

  const handleUpdateToken = async () => {
    try {
      await updateToken().unwrap();
      enqueueSnackbar("Site List synced successfully", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Site List sync failed. Please try again.", {
        variant: "error",
      });
    }
  };

  const HomeButton = () => {
    return (
      <Link
        component={RouterLink}
        underline="always"
        title="Home"
        color="secondary"
        replace={false}
        to="/"
        aria-label="Navigate to the Home Page"
      >
        <HomeIcon />
      </Link>
    );
  };

  const SiteLink = useCallback(() => {
    return (
      <Link
        underline="hover"
        component={RouterLink}
        to={`/site/${pathNames[1]}/${pathNames[2]}`}
        color="secondary"
        aria-label="Navigate to the Site"
      >
        Site
      </Link>
    );
  }, [pathNames]);

  const getBreadCrumbs = useCallback(() => {
    if (pathNames.length === 0) {
      return (
        <>
          <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
            <HomeButton />
            <Typography>Dashboard</Typography>
          </Breadcrumbs>
          <AppBarHeader>Dashboard</AppBarHeader>
        </>
      );
    }
    switch (pathNames[0]) {
      case "site":
        switch (pathNames[3]) {
          case "settings":
            return (
              <>
                <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
                  <HomeButton />
                  <SiteLink />
                  <Typography>Settings</Typography>
                </Breadcrumbs>
                <AppBarHeader>
                  {isSiteParams(params)
                    ? params.siteNameAndLocation
                    : "Missing Site Name!"}
                </AppBarHeader>
              </>
            );
          case "leoQuickAlertLogs":
            return (
              <>
                <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
                  <HomeButton />
                  <SiteLink />
                  <Typography>LEO Alert Logs</Typography>
                </Breadcrumbs>
                <AppBarHeader>
                  {isSiteParams(params)
                    ? params.siteNameAndLocation
                    : "Missing Site Name!"}
                </AppBarHeader>
              </>
            );
          case "users":
            return (
              <>
                <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
                  <HomeButton />
                  <SiteLink />
                  <Typography>Users</Typography>
                </Breadcrumbs>
                <AppBarHeader>
                  {isSiteParams(params)
                    ? params.siteNameAndLocation
                    : "Missing Site Name!"}
                </AppBarHeader>
              </>
            );
          case "userOptions":
            return (
              <>
                <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
                  <HomeButton />
                  <SiteLink />
                  <Typography>User Options</Typography>
                </Breadcrumbs>
                <AppBarHeader>
                  {isSiteParams(params)
                    ? params.siteNameAndLocation
                    : "Missing Site Name!"}
                </AppBarHeader>
              </>
            );
          case "alarms":
            return (
              <>
                <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
                  <HomeButton />
                  <SiteLink />
                  <Typography>Alarms</Typography>
                </Breadcrumbs>
                <AppBarHeader>
                  {isStoreParams(params)
                    ? params.storeNameAndLocation
                    : "Missing Site Name!"}
                </AppBarHeader>
              </>
            );
          case "analysis":
            return (
              <>
                <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
                  <HomeButton />
                  <SiteLink />
                  <Typography>Analysis</Typography>
                </Breadcrumbs>
                <AppBarHeader>
                  {isStoreParams(params)
                    ? params.storeNameAndLocation
                    : "Missing Site Name!"}
                </AppBarHeader>
              </>
            );
          case "customGraph":
            return (
              <>
                <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
                  <HomeButton />
                  <SiteLink />
                  <Typography>Custom Graphs</Typography>
                </Breadcrumbs>
                <AppBarHeader>
                  {isStoreParams(params)
                    ? params.storeNameAndLocation
                    : "Missing Site Name!"}
                </AppBarHeader>
              </>
            );
          case "controller":
            if (pathNames[5] && pathNames[5] === "graph") {
              return (
                <>
                  <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
                    <HomeButton />
                    <SiteLink />
                    <Typography>Controller</Typography>
                    <Link
                      underline="hover"
                      component={RouterLink}
                      to={`/site/${pathNames[1]}/${pathNames[2]}/${pathNames[3]}/${pathNames[4]}`}
                      color={"secondary"}
                      aria-label="Navigate to the Controller"
                    >
                      {isStoreControllerParams(params)
                        ? params.controllerName
                        : "Missing Controller Name!"}
                    </Link>
                    <Typography>Graph</Typography>
                    <Typography>
                      {isStoreControllerApplicationGraphParams(params)
                        ? params.applicationName
                        : "Missing Application Name!"}
                    </Typography>
                  </Breadcrumbs>
                  <Typography noWrap variant="h6" component={"h6"}>
                    {isStoreControllerParams(params)
                      ? `${params.storeNameAndLocation}`
                      : "Missing Site Name!"}
                  </Typography>
                </>
              );
            } else {
              return (
                <>
                  <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
                    <HomeButton />
                    <SiteLink />
                    <Typography>Controller</Typography>
                    <Typography>
                      {isStoreControllerParams(params)
                        ? params.controllerName
                        : "Missing Controller Name!"}
                    </Typography>
                  </Breadcrumbs>
                  <AppBarHeader>
                    {isStoreControllerParams(params)
                      ? `${params.storeNameAndLocation}`
                      : "Missing Store Name!"}
                  </AppBarHeader>
                </>
              );
            }
          case "controllerType":
            if (pathNames[5] && pathNames[5] === "graph") {
              return (
                <>
                  <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
                    <HomeButton />
                    <SiteLink />

                    <Typography>Controller Type</Typography>

                    <Link
                      underline="hover"
                      component={RouterLink}
                      to={`/site/${pathNames[1]}/${pathNames[2]}/${pathNames[3]}/${pathNames[4]}`}
                      color={"secondary"}
                      aria-label="Navigate to the Controller Type"
                    >
                      {isStoreControllerTypeParams(params)
                        ? params.controllerType
                        : "Missing Controller Type!"}
                    </Link>
                    <Typography>Graph</Typography>
                    <Typography>
                      {isStoreControllerTypeGraphParams(params)
                        ? params.controllerName
                        : "Missing Controller Name!"}
                    </Typography>
                  </Breadcrumbs>
                  <AppBarHeader>
                    {isStoreControllerTypeParams(params)
                      ? `${params.storeNameAndLocation}`
                      : "Missing Site Name!"}
                  </AppBarHeader>
                </>
              );
            } else {
              return (
                <>
                  <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
                    <HomeButton />
                    <SiteLink />

                    <Typography>Controller Type</Typography>
                    <Typography>
                      {isStoreControllerTypeParams(params)
                        ? params.controllerType
                        : "Missing Controller Type!"}
                    </Typography>
                  </Breadcrumbs>
                  <AppBarHeader>
                    {isStoreControllerTypeParams(params)
                      ? `${params.storeNameAndLocation}`
                      : "Missing Store Name!"}
                  </AppBarHeader>
                </>
              );
            }
          case "terminalMode":
            return (
              <>
                <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
                  <HomeButton />
                  <SiteLink />

                  <Typography>Terminal Mode</Typography>
                </Breadcrumbs>
                <AppBarHeader>
                  {isStoreParams(params)
                    ? params.storeNameAndLocation
                    : "Missing Site Name!"}
                </AppBarHeader>
              </>
            );
          case "floorplans":
            return (
              <>
                <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
                  <HomeButton />
                  <SiteLink />

                  <Typography>Floor plans</Typography>
                </Breadcrumbs>
                <AppBarHeader>
                  {isStoreParams(params)
                    ? params.storeNameAndLocation
                    : "Missing Site Name!"}
                </AppBarHeader>
              </>
            );
          case undefined:
            return (
              <>
                <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
                  <HomeButton />
                  <SiteLink />
                </Breadcrumbs>
                <AppBarHeader>
                  {isSiteParams(params)
                    ? params.siteNameAndLocation
                    : "Missing Site Name!"}{" "}
                </AppBarHeader>
              </>
            );
          default:
            console.log("UNHANDLED PATHNames[3] for SITE : ", pathNames[3]);
        }
        break;
      case "leoQuickAlertLogs":
        return (
          <>
            <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
              <HomeButton />
              <Typography>All LEO QuickAlert Logs</Typography>
            </Breadcrumbs>
            <AppBarHeader>All LEO QuickAlert Logs</AppBarHeader>
          </>
        );
      case "allSitesAlarms":
        return (
          <>
            <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
              <HomeButton />
              <Typography>All Sites Alarms</Typography>
            </Breadcrumbs>
            <AppBarHeader>All Sites Alarms</AppBarHeader>
          </>
        );
      case "siteList":
        return (
          <>
            <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
              <HomeButton />
              <Typography>Site List</Typography>
            </Breadcrumbs>
            <AppBarHeader>Site List</AppBarHeader>
          </>
        );
      case "adminPortal":
        return (
          <>
            <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
              <HomeButton />
              <Typography>Admin Portal</Typography>
            </Breadcrumbs>
            <AppBarHeader>Admin Portal</AppBarHeader>
          </>
        );
      case "analytics":
        return (
          <>
            <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
              <HomeButton />
              <Typography>Analytics</Typography>
            </Breadcrumbs>
            <AppBarHeader>Analytics</AppBarHeader>
          </>
        );
      case "alarmActivity":
        return (
          <>
            <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
              <HomeButton />
              <Typography>Alarm Activity</Typography>
            </Breadcrumbs>
            <AppBarHeader>Alarm Activity</AppBarHeader>
          </>
        );
      case "sitesWithActiveAlarms":
        return (
          <>
            <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
              <HomeButton />
              <Typography>Sites With Active Alarms</Typography>
            </Breadcrumbs>
            <AppBarHeader>Sites With Active Alarms</AppBarHeader>
          </>
        );
      case "offlineSites":
        return (
          <>
            <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
              <HomeButton />
              <Typography>Offline Sites</Typography>
            </Breadcrumbs>
            <AppBarHeader>Offline Sites</AppBarHeader>
          </>
        );
      case "userAccount":
        return (
          <>
            <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
              <HomeButton />
              <Typography>User Account</Typography>
            </Breadcrumbs>
            <AppBarHeader>User Account</AppBarHeader>
          </>
        );
      case "settings":
        return (
          <>
            <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
              <HomeButton />
              <Typography>Settings</Typography>
            </Breadcrumbs>
            <AppBarHeader>Settings</AppBarHeader>
          </>
        );
      case "help":
        return (
          <>
            <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
              <HomeButton />
              <Typography>Help</Typography>
            </Breadcrumbs>
            <AppBarHeader>Help</AppBarHeader>
          </>
        );
    }
  }, [params, pathNames, SiteLink]);

  return (
    <Grid item>
      <Toolbar
        disableGutters
        sx={{
          alignItems: "flex-start",
        }}
      >
        <ToggleMenu
          sx={{
            position: "relative",
            marginRight: {
              xs: 1,
              sm: 2,
            },
          }}
        />

        <Box
          flex={"1 1 0"}
          minWidth={0}
          aria-description="breadcrumb and title container"
        >
          {getBreadCrumbs()}
        </Box>

        <IconButton
          disabled={isTokenUpdating}
          title="Sync Site List"
          onClick={handleUpdateToken}
        >
          <SyncIcon
            sx={{
              "@keyframes spin": {
                from: { transform: "rotate(0deg)" },
                to: { transform: "rotate(-360deg)" },
              },
              color: (theme) =>
                isTokenUpdating
                  ? theme.palette.text.disabled
                  : theme.palette.text.primary,
              animation: isTokenUpdating ? "spin 1.5s linear infinite" : "none",
              transition: "color 0.5s",
            }}
          />
        </IconButton>
        <IconButton
          title="Toggle Color Preference"
          onClick={handleColorModeToggle}
        >
          {colorMode === "light" ? <DarkModeIcon /> : <LightModeIcon />}
        </IconButton>

        {/* <NotificationsIconsButton /> */}
      </Toolbar>
    </Grid>
  );
};

export default Header;
