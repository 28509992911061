import { useState, memo } from "react";
import {
  DataGrid as DGrid,
  DataGridProps,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridToolbarProps,
  GridToolbarFilterButtonProps,
} from "@mui/x-data-grid";
import Box, { BoxProps } from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled, darken, lighten } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { yellow } from "@mui/material/colors";
import { useAppSelector } from "../../app/hooks";
import { selectIsTesting } from "../../features/view/viewSlice";
import { blue } from "@mui/material/colors";

type Props = DataGridProps & {
  removeTopMargin?: boolean;
  title?: string;
  quickFilter?: boolean;
  customButtons?: (ButtonProps & {
    btnText: string;
    onClick: () => void;
  })[];
};

type ToolbarProps = GridToolbarProps & {
  title?: string;
  quickFilter?: boolean;
  customButtons?: (ButtonProps & {
    btnText: string;
    onClick: () => void;
  })[];
  filterButtonProps?: GridToolbarFilterButtonProps;
};

function CustomToolbar({
  printOptions,
  title,
  quickFilter,
  customButtons,
  ...props
}: ToolbarProps) {
  return (
    <GridToolbarContainer sx={{ display: "flex", columnGap: 1 }} {...props}>
      {customButtons?.map(({ btnText, onClick, ...bProps }) => {
        return (
          <Button
            size="small"
            variant="contained"
            key={btnText}
            onClick={onClick}
            {...bProps}
          >
            {btnText}
          </Button>
        );
      })}
      <GridToolbarExport printOptions={printOptions} variant="outlined" />
      {/* <GridToolbarFilterButton {...filterButtonProps} /> */}
      {title && (
        <Typography
          //position="absolute"
          textAlign="center"
          variant="h5"
          component="h3"
          flexGrow={1}
          //left={'50%'}
          //top={0}
          //sx={{ transform: 'translate(-50%, 0%)' }}
        >
          {title}
        </Typography>
      )}
      {quickFilter && (
        <GridToolbarQuickFilter
          variant="standard"
          size="small"
          sx={{
            marginLeft: "auto",
            // [`& .${inputBaseClasses.root}`]: {
            //     borderTopLeftRadius: 3,
            //     borderTopRightRadius: 3,
            //     backgroundColor: (theme) =>
            //         theme.palette.mode === 'dark'
            //             ? 'rgba(255,255,255,0.1)'
            //             : 'rgba(0,0,255,0.1)',
            //     transition: (theme) =>
            //         theme.transitions.create(['background-color', 'border']),
            //     [`&.${inputBaseClasses.focused}`]: {
            //         backgroundColor: 'transparent',
            //     },
            // },
          }}
        />
      )}
    </GridToolbarContainer>
  );
}

const MyDGrid = styled(DGrid)<Props>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  flexGrow: 1,
  height: "auto",
  maxWidth: 1500,
  minHeight: 300,
  "& .MuiDataGrid-filterFormValueInput": {
    width: 500,
    border: "1px solid green",
  },
  "& .MuiDataGrid-virtualScrollerContent": {
    backgroundColor: darken(theme.palette.background.paper, 0.15),
  },
  "& .my-dgrid-row": {
    backgroundColor: darken(theme.palette.background.paper, 0.15),
  },
  "& .my-dgrid-row:nth-of-type(even)": {
    backgroundColor: darken(theme.palette.background.paper, 0.05),
  },
  "& .my-dgrid-row:hover": {
    backgroundColor: lighten(theme.palette.background.paper, 0.15),
  },
  "& .red-row": {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  "& .red-row--odd": {
    backgroundColor: theme.palette.error[theme.palette.mode],
    color: theme.palette.error.contrastText,
  },
  "& .red-row:hover": {
    backgroundColor: lighten(theme.palette.error.main, 0.15),
    cursor: "pointer",
  },
  "& .red-row--odd:hover": {
    backgroundColor: lighten(theme.palette.error[theme.palette.mode], 0.15),
    cursor: "pointer",
  },
  "& .yellow-row": {
    backgroundColor: theme.palette.mode === "dark" ? yellow[600] : yellow[300],
    color:
      theme.palette.mode === "dark"
        ? theme.palette.getContrastText(yellow[600])
        : theme.palette.getContrastText(yellow[300]),
  },
  "& .yellow-row--odd": {
    backgroundColor: theme.palette.mode === "dark" ? yellow[500] : yellow[400],
    color:
      theme.palette.mode === "dark"
        ? theme.palette.getContrastText(yellow[500])
        : theme.palette.getContrastText(yellow[400]),
  },
  "& .yellow-row:hover": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? lighten(yellow[600], 0.15)
        : lighten(yellow[300], 0.15),
    cursor: "pointer",
  },
  "& .yellow-row--odd:hover": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? lighten(yellow[500], 0.15)
        : lighten(yellow[400], 0.15),
    cursor: "pointer",
  },
  "& .green-row": {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  "& .green-row--odd": {
    backgroundColor: theme.palette.success[theme.palette.mode],
    color: theme.palette.success.contrastText,
  },
  "& .green-row:hover": {
    backgroundColor: lighten(theme.palette.success.main, 0.15),
    cursor: "pointer",
  },
  "& .green-row--odd:hover": {
    backgroundColor: lighten(theme.palette.success[theme.palette.mode], 0.15),
    cursor: "pointer",
  },
  "& .blue-row": {
    backgroundColor: theme.palette.mode === "dark" ? blue[900] : blue[400],
    color: theme.palette.getContrastText(
      theme.palette.mode === "dark" ? blue[900] : blue[400],
    ),
  },
  "& .blue-row:hover": {
    backgroundColor: lighten(
      theme.palette.mode === "dark" ? blue[900] : blue[400],
      0.15,
    ),
    cursor: "pointer",
  },
  "& .blue-row--odd": {
    backgroundColor: theme.palette.mode === "dark" ? blue[800] : blue[300],
    color: theme.palette.getContrastText(
      theme.palette.mode === "dark" ? blue[800] : blue[300],
    ),
  },
  "& .blue-row--odd:hover": {
    backgroundColor: lighten(
      theme.palette.mode === "dark" ? blue[800] : blue[300],
      0.15,
    ),
    cursor: "pointer",
  },

  "& .active-alarm_source-cell": {
    position: "relative",
    "&:hover": {
      outline: `1px solid ${theme.palette.primary.main}`,
      cursor: "pointer",
      "&:after": {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        content: '"click to graph"',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
      },
    },
  },
}));

type DataGridBoxProps = BoxProps & {
  removemargintop?: string;
};

const DataGridBox = styled(Box)<DataGridBoxProps>(
  ({ theme, removemargintop }) => ({
    flexGrow: 1,
    marginTop: theme.spacing(3),
    // minHeight: 300,
    //maxWidth: 1200,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      ...(removemargintop === "true" && {
        marginTop: 0,
      }),
    },
    ...(removemargintop === "true" && {
      marginTop: 0,
    }),
  }),
);

const DataGrid = ({
  removeTopMargin,
  title,
  quickFilter,
  customButtons,
  ...props
}: Props) => {
  const isTesting = useAppSelector(selectIsTesting);
  const [page, setPage] = useState(0);
  return (
    <DataGridBox
      removemargintop={removeTopMargin ? removeTopMargin.toString() : undefined}
    >
      <MyDGrid
        page={page <= 0 ? 0 : page}
        onPageChange={(pageNum) => setPage(pageNum)}
        density="compact"
        className="my-dgrid"
        getRowClassName={() => "my-dgrid-row"}
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{
          toolbar: {
            printOptions: {
              hideToolBar: true,
              hideFooter: true,
              allColumns: true,
            },
            title,
            quickFilter,
            customButtons,
          },
          loadingOverlay: {
            "data-testid": "dataGridLoadingOverlay",
          },
          filterPanel: {
            sx: {
              "& .MuiDataGrid-filterFormValueInput": {
                flex: "190px 1 1",
                width: "auto",
              },
            },
          },
        }}
        disableVirtualization
        disableSelectionOnClick
        {...props}
        // override autoPageSize for tests to work
        {...(isTesting ? { autoPageSize: false } : {})}
      />
    </DataGridBox>
  );
};

export default memo(DataGrid);
