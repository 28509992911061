import { PaletteMode } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getCookie, setCookie } from "../../utils/cookieHandlers";

export interface IExpiredStore {
  storeName: string;
  storeNum: number;
  expirationDate: string;
}
export type IExpiredStoresList = IExpiredStore[];

export type OldCookiesColorPallete = {
  primary: {
    main: string;
  };
  secondary: {
    main: string;
  };
  error: {
    main: string;
  };
  warning: {
    main: string;
  };
  info: {
    main: string;
  };
  success: {
    main: string;
  };
};

function isOldCookiesColorPalette(obj: unknown): obj is OldCookiesColorPallete {
  return (
    obj !== null &&
    obj !== undefined &&
    typeof obj === "object" &&
    "primary" in obj &&
    !("background" in obj)
  );
}

export type EditablePallete = {
  primary: {
    main: string;
  };
  secondary: {
    main: string;
  };
  error: {
    main: string;
  };
  warning: {
    main: string;
  };
  info: {
    main: string;
  };
  success: {
    main: string;
  };
  background: {
    default: {
      light: string;
      dark: string;
    };
    paper: {
      light: string;
      dark: string;
    };
  };
};

function isNewCookiesColorPalette(obj: unknown): obj is EditablePallete {
  return (
    obj !== null &&
    obj !== undefined &&
    typeof obj === "object" &&
    "primary" in obj &&
    "background" in obj
  );
}

export type CookiesColorPallete = OldCookiesColorPallete | EditablePallete;
export interface ViewState {
  mode: PaletteMode;
  palette: EditablePallete;
  showDrawer: boolean;
  showStores: boolean;
  showSessionExpiredDialog: boolean;
  showExpiredStoresDialog: boolean;
  expiredStores: IExpiredStoresList;
  showUnauthorizedSiteAccess: boolean;
  storeToFocus: number | null;
  disableDashboardMap: boolean;
  isSmallDevice: boolean;
  isMediumDevice: boolean;
  isDevToolsOpen: boolean;
  isTestingEnv: boolean;
}

export const PALETTE_DEFAULT: EditablePallete = {
  // CURRENT THEME
  primary: {
    // orignal in comments
    // HLGREEN = #2a652a
    main: "#548354", // '#158eec',
  },
  secondary: {
    main: "#158eec", // '#ec7215',
  },
  error: {
    main: "#f44336",
  },
  success: {
    main: "#2e7d32", // #40bf6d <- This is a great looking green text color but not so good on the sidebar
  },
  warning: {
    main: "#ed6c02",
  },
  info: {
    main: "#f5f3e5", // '#0288d1',
  },
  background: {
    default: {
      light: "#fafafa",
      dark: "#303030",
    },
    paper: {
      light: "#ffffff",
      dark: "#424242",
    },
  },
};

const COOKIE_NAME = "ColorPalette";

// need to Modify Old palettes to include the background
const getColorPaletteFromCookies = () => {
  const cookies = document.cookie.split(";");

  for (const cookie of cookies) {
    const [cookieName, cookieVal] = cookie.trim().split("=");

    if (cookieName === COOKIE_NAME) {
      // need to modify old palettes to include background
      const colorPalette = JSON.parse(cookieVal) as CookiesColorPallete;
      console.info({ colorPalette });
      if (isOldCookiesColorPalette(colorPalette)) {
        const updatedPalette: EditablePallete = {
          ...colorPalette,
          background: PALETTE_DEFAULT.background,
        };
        console.info("modified old colorPalette");
        return updatedPalette;
      } else if (isNewCookiesColorPalette(colorPalette)) {
        console.info("no modification to palettte needed");
        return colorPalette;
      }
    }
  }

  return PALETTE_DEFAULT;
};

const saveColorPaletteToCookies = (colorPalette: EditablePallete) => {
  document.cookie = `${COOKIE_NAME}=${JSON.stringify(
    colorPalette,
  )}; expires=Thu, 18 Dec 2100 12:00:00 UTC; path=/; samesite=strict;`;
};

const getDisableDashboardMap = () => {
  const COOKIE_NAME = "disableDashboardMap";
  const DEFAULT_VALUE = false;

  const cookieValueUnparsed = getCookie(COOKIE_NAME);

  if (cookieValueUnparsed) {
    return cookieValueUnparsed === "true";
  }

  return DEFAULT_VALUE;
};

const updateDisableDashboardMap = (value: boolean) => {
  const COOKIE_NAME = "disableDashboardMap";
  setCookie(COOKIE_NAME, value.toString());
};

const checkIfTestingEnv = () => {
  if (process.env.JEST_WORKER_ID !== undefined) {
    return true;
  }
  return false;
};

const initialState: ViewState = {
  mode: "light",
  showDrawer: true,
  showStores: false,
  showSessionExpiredDialog: false,
  showExpiredStoresDialog: false,
  showUnauthorizedSiteAccess: false,
  expiredStores: [],
  storeToFocus: null,
  isSmallDevice: false,
  isMediumDevice: false,
  palette: getColorPaletteFromCookies(),
  disableDashboardMap: getDisableDashboardMap(),
  isDevToolsOpen: false,
  isTestingEnv: checkIfTestingEnv(),
};

export const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    toggleMode: (state) => {
      if (state.mode === "dark") state.mode = "light";
      else state.mode = "dark";
    },
    toggleDrawer: (state) => {
      if (state.showDrawer) {
        state.showDrawer = false;
      } else {
        state.showDrawer = true;
      }
    },
    toggleStoresDrawer: (state) => {
      // need to make sure this gets toggled off when a user logs out
      if (state.showStores) {
        state.showStores = false;
      } else {
        state.showStores = true;
      }
    },
    showSessionExpiredDialog: (state, action: PayloadAction<boolean>) => {
      state.showSessionExpiredDialog = action.payload;
    },
    closeSessionExpiredDialog: (state) => {
      state.showSessionExpiredDialog = false;
    },
    closeExpiredStoresDialog: (state) => {
      state.showExpiredStoresDialog = false;
    },
    showExpiredStoresDialog: (
      state,
      action: PayloadAction<IExpiredStoresList>,
    ) => {
      state.expiredStores = action.payload;
      state.showExpiredStoresDialog = true;
    },
    forceCloseDrawerAndStores: (state) => {
      state.showStores = false;
      state.showDrawer = false;
    },
    focusStore: (state, action: PayloadAction<number>) => {
      state.showDrawer = true;
      state.showStores = true;
      state.storeToFocus = action.payload;
    },
    clearOutFocusStoreState: (state) => {
      state.storeToFocus = null;
    },
    setIsSmallDevice: (state, action: PayloadAction<boolean>) => {
      state.isSmallDevice = action.payload;
    },
    setIsMediumDevice: (state, action: PayloadAction<boolean>) => {
      state.isMediumDevice = action.payload;
    },
    setThemePalette: (state, action: PayloadAction<EditablePallete>) => {
      saveColorPaletteToCookies(action.payload);
      state.palette = action.payload;
    },
    setDisableDashboardMap: (state, action: PayloadAction<boolean>) => {
      updateDisableDashboardMap(action.payload);
      state.disableDashboardMap = action.payload;
    },
    handleShowUnauthorizedSiteAccess: (state) => {
      state.showUnauthorizedSiteAccess = true;
    },
    handleHideUnauthorizedSiteAccess: (state) => {
      state.showUnauthorizedSiteAccess = false;
    },
    setDevToolsOpen: (state) => {
      // code is setup in App.tsx for this to be used
      state.isDevToolsOpen = true;
    },
    setDevToolsClosed: (state) => {
      state.isDevToolsOpen = false;
    },
  },
});

export const {
  toggleMode,
  toggleDrawer,
  toggleStoresDrawer,
  showSessionExpiredDialog,
  closeSessionExpiredDialog,
  closeExpiredStoresDialog,
  showExpiredStoresDialog,
  forceCloseDrawerAndStores,
  focusStore,
  clearOutFocusStoreState,
  setIsSmallDevice,
  setIsMediumDevice,
  setThemePalette,
  handleShowUnauthorizedSiteAccess,
  handleHideUnauthorizedSiteAccess,
  setDisableDashboardMap,
  setDevToolsOpen,
  setDevToolsClosed,
} = viewSlice.actions;

export const selectTheme = (state: RootState) => state.view.mode;
export const selectCustomThemePalette = (state: RootState) =>
  state.view.palette;

export const selectShowSessionExpiredDialog = (state: RootState) =>
  state.view.showSessionExpiredDialog;
export const selectShowExpiredStoresDialog = (state: RootState) =>
  state.view.showExpiredStoresDialog;

export const selectShowUnauthorizedSiteAccess = (state: RootState) =>
  state.view.showUnauthorizedSiteAccess;
export const selectExpiresStoresList = (state: RootState) =>
  state.view.expiredStores;
export const selectShowDrawer = (state: RootState) => state.view.showDrawer;
export const selectShowStoresDrawer = (state: RootState) =>
  state.view.showStores;
export const selectStoreToFocus = (state: RootState) => state.view.storeToFocus;
export const selectDisableDashboardMap = (state: RootState) =>
  state.view.disableDashboardMap;

export const selectIsTesting = (state: RootState) => state.view.isTestingEnv;

export const selectIsDevToolsOpen = (state: RootState) =>
  state.view.isDevToolsOpen; // CURRENTLY NOT USED (LOGIC IS MOVED INTO THE RemoteAccess.tsx) commented out codes in App.tsx

export const selectDeviceSize = (state: RootState) => ({
  isSmallDevice: state.view.isSmallDevice,
  isMediumDevice: state.view.isMediumDevice,
});

export default viewSlice.reducer;
