import Grid, { GridProps } from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

const SingleItemGridContainer = styled((props) => (
  <Grid item container {...props} />
))<GridProps>(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));

export default SingleItemGridContainer;
