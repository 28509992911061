import TabPanel, { TabPanelProps } from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";

type CustomTabPanelProps = TabPanelProps & {
  center?: "true";
};

const CustomTabPanel = styled(TabPanel)<CustomTabPanelProps>(({ center }) => ({
  padding: 0,
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  //overflowY: "hidden",
  opacity: 1,
  transition: "opacity 0.5s",
  //minHeight: 0,
  "&[hidden]": {
    flexGrow: 0,
    opacity: 0,
  },
  ...(center && {
    alignItems: "center",
  }),
}));
export default CustomTabPanel;
