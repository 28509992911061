import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store"; // this import for store is causing a ciurcular dependency!!!!!!
import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { returnEndPointKey } from "./apiEndPointNames";
import {
  showSessionExpiredDialog,
  handleShowUnauthorizedSiteAccess,
} from "../../features/view/viewSlice";
import { logout } from "../../features/auth/authSlice";
import { resetHiddenChains } from "../../features/sites/sitesSlice";
import baseApiUrl from "./baseApiUrl";

// All requests that use this endpoint will extend from it by injecting endPoints

// need to handle providing the correct API route
// winndow.location.hostname should work fine fo testing

// may need some logic here to properly set the baseUrl for all requests

export const api = createApi({
  reducerPath: "api",

  baseQuery: fetchBaseQuery({
    // all requests will use this url unless we have other baseURL required
    baseUrl: baseApiUrl,
    prepareHeaders: (headers, { getState }) => {
      // set the authorization header on every request it is available
      // maybe check the token here??? for the refresh token
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    },
    timeout: 600000, // 10 Minutes is way to long to wait
  }),
  tagTypes: [
    "Users",
    "Chains",
    "Sites",
    "SiteUsers",
    "Applications",
    "Floorplans",
    "FloorplanMarkers",
    "AlarmReceivers",
    "AlarmFilters",
  ],
  endpoints: () => ({}),
});

/**
 * Log a warning and show a toast! Doesn't seem to be working currently.
 */
export const rtkQueryErrorLogger: Middleware =
  (_api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
      console.warn("We got a rejected action!");
      console.log(action);

      // @ts-ignore
      const endPointName = action?.meta?.arg?.endpointName as string;
      // @ts-ignore
      const type = action?.meta?.arg?.type;
      // @ts-ignore
      const status = action?.payload?.status;
      // @ts-ignore
      const message = action?.payload?.data?.message;

      if (
        status &&
        status === 401 &&
        message &&
        (message?.toLowerCase() === "invalid token" ||
          message?.toLowerCase() === "signature expired")
      ) {
        console.info(
          "%clogging out because 401 response (the server said the token is invalid)",
          "color: orange",
        );
        _api.dispatch(showSessionExpiredDialog(true));
        _api.dispatch(logout());
        _api.dispatch(resetHiddenChains());
      } else if (
        status &&
        status === 403 &&
        message?.toLowerCase() === "unauthorized access to store"
      ) {
        enqueueSnackbar({
          message: "Unauthorized access to site",
          variant: "error",
          SnackbarProps: { title: "Error" },
          preventDuplicate: true,
        });
        _api.dispatch(handleShowUnauthorizedSiteAccess());
      } else {
        // type: "mutation" is handled within the components
        if (type === "query") {
          const message = `Failed to fetch ${returnEndPointKey(endPointName)}`;
          // @ts-ignore
          const title = `${action.payload.status} Error`;
          console.info("Creating SnackBar!!!");
          console.info({ title, message });
          enqueueSnackbar({
            message,
            variant: "error",
            SnackbarProps: { title: title },
            preventDuplicate: true,
          });
        }
      }
    }

    return next(action);
  };
