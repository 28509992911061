export default function useCheckDomainAndRedirect() {
  const origin = window.location.origin;
  if (
    process.env.REACT_APP_USE_PROD_URI === 'YES' &&
    origin === 'https://leocloud-react.azurewebsites.net'
  ) {
    // handle switching to www.leocloud.us
    window.location.replace('https://www.leocloud.us');
  }
}
