/* eslint-disable @typescript-eslint/no-non-null-assertion */
import CacheBuster from "react-cache-buster";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/Utility/ErrorFallback";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import packageInfo from "../package.json";
import { setupStore } from "./app/store";
import App from "./App";
import "./index.css";
import "./App.scss";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const container = document.getElementById("root")!;
const root = createRoot(container);

const isProduction = process.env.NODE_ENV === "production";

root.render(
  <StrictMode>
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error) => {
        console.error(error);
      }}
    >
      <CacheBuster
        isEnabled={isProduction}
        currentVersion={packageInfo.version}
      >
        <Provider store={setupStore()}>
          <App />
        </Provider>
      </CacheBuster>
    </ErrorBoundary>
  </StrictMode>,
);
