import { SyntheticEvent, useState, useMemo, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import ActiveAlarmsTable from "../../../components/Alarms/ActiveAlarmsTable";
import HistoricalAlarmsTable from "../../../components/Alarms/HistoricalAlarmsTable";
import OverrideAlarmsTable from "../../../components/Alarms/OverrideAlarmsTable";
import {
  SiteAlarmType,
  useGetAlarmsForStoreQuery,
} from "../../../features/alarms/alarmsAPI";
import { TabsActions } from "@mui/material/Tabs";
import { useGetSitesQuery } from "../../../features/sites/sitesAPI";
import {
  useSelectFilteredSitesSelector,
  useAppSelector,
} from "../../../app/hooks";
import useHandleUpdateUrlWithTabValue from "../../../hooks/appHooks/useHandleUpdateUrlWithTabValue";
import { selectSiteList } from "../../../features/auth/authSlice";
import { AlarmsTabs } from "../../AllSiteAlarms/AllSiteAlarms";
import { DateTime } from "luxon";
import CustomTabPanel from "../../../components/Tabs/CustomTabPanel";

export const nonFilteredAlarmStrings = [
  "rack fail dishcharge trip",
  "rack fail phase fail",
  "refr phase loss",
  "high discharge limit exceeded",
  "high suction limit exceeded",
  "case temp hi limit exceeded",
  "discharge trip",
  "leo cannot communicate with leocloud",
];
export const deviceAbsentValidApps = ["16ai", "8ro", "4ao", "8do"];

// need to confirm why this page tabPanels take up more than 100%
type Props = {
  siteId: number;
  siteNameAndLocation: string;
  tab?: AlarmsTabs;
};

const SiteAlarms = ({ siteId, siteNameAndLocation, tab }: Props) => {
  const actionRef = useRef<TabsActions | null>(null);
  const tabListRef = useRef<HTMLDivElement | null>(null);
  const site_list = useAppSelector(selectSiteList);
  // const filteredSiteList = useAppSelector(selectFilteredSiteList);

  useEffect(() => {
    if (!tabListRef.current || !actionRef.current) return;

    const resizeObv = new ResizeObserver((_e, _o) => {
      actionRef.current?.updateIndicator();
    });
    resizeObv.observe(tabListRef.current);

    return () => resizeObv.disconnect();
  }, []);

  const selectFilteredSites = useSelectFilteredSitesSelector();

  // I should be able to set the cache up in a way to update this store for the rest
  // maybe just request this siteId
  const { data: sitesData, isLoading: isLoadingStoreData } = useGetSitesQuery(
    {
      store_list: site_list,
    },
    {
      selectFromResult: (result) => ({
        ...result,
        data: selectFilteredSites(result, [siteId]),
      }),
    },
  );

  const siteData = useMemo(() => {
    if (sitesData) {
      const site = sitesData.find((d) => d.store_num === siteId);
      if (site) return site;
    }
  }, [sitesData, siteId]);
  const isInglesSite = useMemo(() => {
    if (siteData) {
      return siteData.store_operator === "INGLES";
    }
    return false;
  }, [siteData]);

  const { data, isLoading } = useGetAlarmsForStoreQuery(
    { storeId: siteId },
    {
      pollingInterval: 120000,
      refetchOnMountOrArgChange: 30,
    },
  );

  const storeAlarms = useMemo(() => {
    // returns an aarray of alarms for each store
    const obj: {
      active: SiteAlarmType[];
      historical: SiteAlarmType[];
      override: SiteAlarmType[];
    } = {
      active: [],
      historical: [],
      override: [],
    };
    if (data && data[0] && data[0].store_num === siteId) {
      for (const alrm of data[0].alarms) {
        const alarm = structuredClone(alrm) as SiteAlarmType;
        alarm.siteId = siteId;
        alarm.siteNameAndLocation = siteNameAndLocation;
        alarm.filtered = false;

        // device absent from network needs to match certain applications

        if (isInglesSite) {
          alarm.filtered = true;
          // check for filtered vals
          if (
            nonFilteredAlarmStrings.includes(alarm.alarm_text.toLowerCase())
          ) {
            // this is a active alarm to show
            alarm.filtered = false;
          } else if (
            alarm.alarm_text.toLowerCase() === "device absent from network"
          ) {
            for (const val of deviceAbsentValidApps) {
              if (alarm.alarm_source.toLowerCase().includes(val)) {
                alarm.filtered = false;
                break;
              }
            }
          }
        }

        if (alrm.service_override_date !== null && alrm.alarm_active === 1) {
          // compare date with store timezone if possible``
          // fallback to utc
          if (siteData && siteData.time_zone) {
            const now = DateTime.now().setZone(siteData.time_zone);
            const overrideDate = DateTime.fromISO(
              alrm.service_override_date,
            ).setZone(siteData.time_zone);

            if (now >= overrideDate) {
              obj.active.push(alarm);
            } else {
              obj.override.push(alarm);
            }
          } else {
            const now = DateTime.now().setZone("utc");
            const overrideDate = DateTime.fromISO(
              alrm.service_override_date,
            ).setZone("utc");
            if (now >= overrideDate) {
              obj.active.push(alarm);
            } else {
              obj.override.push(alarm);
            }
          }
        } else if (alrm.alarm_active === 1) {
          obj.active.push(alarm);
        } else {
          obj.historical.push(alarm);
        }
      }
    }
    return obj;
  }, [data, siteId, siteNameAndLocation, isInglesSite, siteData]);

  const [tabValue, setTabValue] = useState(tab ? tab : AlarmsTabs.ACTIVE);

  const handleTabChange = (_event: SyntheticEvent, newValue: AlarmsTabs) => {
    setTabValue(newValue);
  };

  useHandleUpdateUrlWithTabValue(tabValue, 5);

  return (
    <Box
      sx={{
        width: "100%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TabContext value={tabValue}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", position: "relative" }}
        >
          <TabList
            onChange={handleTabChange}
            centered
            indicatorColor="secondary"
            aria-label="tabs for different alarm tables"
            action={actionRef}
            ref={tabListRef}
          >
            <Tab label="Active" value={AlarmsTabs.ACTIVE} />
            <Tab label="Override" value={AlarmsTabs.OVERRIDE} />
            <Tab label="History" value={AlarmsTabs.HISTORY} />
          </TabList>
        </Box>
        <CustomTabPanel value={AlarmsTabs.ACTIVE}>
          <ActiveAlarmsTable
            isLoading={isLoading || isLoadingStoreData}
            alarms={storeAlarms.active}
          />
        </CustomTabPanel>
        <CustomTabPanel value={AlarmsTabs.OVERRIDE}>
          <OverrideAlarmsTable
            alarms={storeAlarms.override}
            isLoading={isLoading}
          />
        </CustomTabPanel>
        <CustomTabPanel value={AlarmsTabs.HISTORY}>
          <HistoricalAlarmsTable
            alarms={storeAlarms.historical}
            isLoading={isLoading}
          />
        </CustomTabPanel>
      </TabContext>
    </Box>
  );
};

export default SiteAlarms;
