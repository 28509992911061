import { api } from "../../app/services/api";

export type AlarmRequestType = "ALL" | "ACTIVE" | "RESOLVED";

export interface GetStoreAlarmsRequest {
  storeId: number;
  // alarm_type: AlarmRequestType;
}
export interface GetStoresAlarmsRequest {
  storeIds: number[];
  // alarm_type: AlarmRequestType;
}

export interface AlarmInterface {
  alarm_active: number;
  alarm_date: string;
  alarm_source: string;
  application: string | null;
  alarm_text: string;
  controller_name: string;
  rtn_date: string | null;
  service_override_date: string | null;
}

export type OverrideAlarmType = Omit<AlarmInterface, "alarmActive"> & {
  overrideUntil: string;
  emailDate: string;
};
export type HistoricalAlarmType = Omit<
  OverrideAlarmType,
  "overrideUntil" | "alarmDate"
>;

export type GetAlarmsForStoreResponse = {
  alarms: AlarmInterface[];
  store_num: number;
}[];

export type GetActiveAlarmsResponse = AlarmInterface[];
export type GetHistoricalAlarmsResponse = HistoricalAlarmType[];
export type GetOverrideAlarmsResponse = OverrideAlarmType[];

export type SiteAlarmType = AlarmInterface & {
  siteId: number;
  filtered: boolean;
  siteNameAndLocation: string;
};
export type AllSitesAlarmType = Omit<SiteAlarmType, "siteNameAndLocation"> & {
  siteName: string;
  siteLocation: string;
};

export interface GetAlarmCountPerYearRequest {
  store_list: number[];
  alarm_list: string[];
}
export interface GetAlarmCountPerYearResponse {
  alarm_count: number;
  alarm_text: string;
  year: number;
  store_num: number;
}

export interface GetSiteAlarmAnalysisRequest {
  store_num: number;
}

export interface GetSiteAlarmAnalysisResponse {
  [controllerName: string]: {
    today: { [key: string]: number };
    "7_days": { [key: string]: number };
    "30_days": { [key: string]: number };
  };
}

export type GetAlarmReportRequest = {
  store_list: number[];
  report_date: string;
};

export type AlarmReportAlarm = {
  rtn_date: string | null;
  alarm_date: string;
  alarm_source: string;
  alarm_text: string;
  controller_name: string;
  service_override_date: string | null;
};

export type GetAlarmReportResponse = {
  store_num: number;
  alarm_report: AlarmReportAlarm[];
}[];

export const alarmsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAlarmsForStore: builder.query<
      GetAlarmsForStoreResponse,
      GetStoreAlarmsRequest
    >({
      query: ({ storeId }) => ({
        url: "alarms/store",
        method: "POST",
        body: {
          store_list: [storeId],
          alarm_type: "ALL",
        },
      }),
    }),
    getAlarmsForStores: builder.query<
      GetAlarmsForStoreResponse,
      GetStoresAlarmsRequest
    >({
      query: ({ storeIds }) => ({
        url: "alarms/store",
        method: "POST",
        body: {
          store_list: storeIds,
          alarm_type: "ALL",
        },
      }),
    }),
    getActiveAlarmsForStore: builder.query<
      GetAlarmsForStoreResponse,
      GetStoreAlarmsRequest
    >({
      query: ({ storeId }) => ({
        url: "alarms/store",
        method: "POST",
        body: {
          store_list: [storeId],
          alarm_type: "ACTIVE",
        },
      }),
    }),
    getAlarmCountPerYear: builder.query<
      GetAlarmCountPerYearResponse[],
      GetAlarmCountPerYearRequest
    >({
      query: (body) => ({
        url: "alarms/count",
        method: "POST",
        body,
      }),
    }),
    getSitesAlarmAnalysis: builder.query<
      GetSiteAlarmAnalysisResponse,
      GetSiteAlarmAnalysisRequest
    >({
      query: (body) => ({
        url: "alarms/site-analysis",
        method: "POST",
        body,
      }),
    }),
    getAlarmReportForSite: builder.query<
      GetAlarmReportResponse,
      GetAlarmReportRequest
    >({
      query: (body) => ({
        url: "alarms/report",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAlarmsForStoreQuery,
  useGetAlarmsForStoresQuery,
  useGetActiveAlarmsForStoreQuery,
  useGetAlarmCountPerYearQuery,
  useGetSitesAlarmAnalysisQuery,
  useGetAlarmReportForSiteQuery,
  useLazyGetAlarmReportForSiteQuery,
} = alarmsApi;
