import Box, { BoxProps } from "@mui/material/Box";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import Grid, { GridProps } from "@mui/material/Grid";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { memo, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useScrollRestoration } from "use-scroll-restoration";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectShowDrawer,
  selectShowUnauthorizedSiteAccess,
  toggleDrawer,
} from "../../features/view/viewSlice";
import useTokenExpirationHandler from "../../hooks/appHooks/useTokenExpirationHandler";
import ExpiredStoresDialog from "../Modals/ExpiredStoresDialog";
import UnauthorizedSiteAccessDialog from "../Modals/UnauthorizedSiteAccessDialog";
import Header from "./Header/Header";
import Menu from "./Menu/Menu";

const HomeContainer = styled(Box)<BoxProps>(() => ({
  display: "flex",
  height: "100%",
  position: "relative",
  width: "auto",
  boxSizing: "border-box",
  flexDirection: "row",
  justifyContent: "flex-start",
}));

const ContentGrid = styled(Grid)<GridProps>(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexWrap: "nowrap",
  flexDirection: "column",
  rowGap: theme.spacing(1),
  scrollbarGutter: "auto",
  position: "relative",
  maxHeight: "100%",
}));

const ContentGridWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  minHeight: "100%", // removing this allows out padding to be applied when the page is overflow but this causes the dashboard not to be full height
  height: "100%",
  maxHeight: "100%",
  width: "100%",
  display: "flex",
  flexWrap: "nowrap",
  flexDirection: "column",
  rowGap: theme.spacing(1),
  padding: theme.spacing(3),
  scrollbarGutter: "auto",
  overflowY: "auto",
  position: "absolute",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1),
  },
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  width: "100%",
  height: "100%",
  overflowX: "hidden",
  overflowY: "hidden",
  WebkitOverflowScrolling: "touch",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  boxShadow: theme.shadows["11"],
  zIndex: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // on small screens we make the sidebar fixed so it overlays the content
  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
  },
  [theme.breakpoints.up("md")]: {
    marginLeft: `-30%`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  },
  [theme.breakpoints.up("lg")]: {
    marginLeft: `-25%`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  },
  [theme.breakpoints.up("xl")]: {
    marginLeft: `-20%`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  },
}));

const MenuDrawer = styled(Drawer)<DrawerProps>(({ theme }) => ({
  height: "100%",
  flexShrink: 0,
  zIndex: 0,
  // flexGrow: 1,
  [theme.breakpoints.between("xs", "sm")]: {
    width: "80%",
    "& .MuiDrawer-paper": {
      width: "80%",
    },
    zIndex: 1,
  },
  [theme.breakpoints.between("sm", "md")]: {
    width: "66%",
    "& .MuiDrawer-paper": {
      width: "66%",
    },
    zIndex: 1,
  },
  [theme.breakpoints.up("md")]: {
    width: "30%",
    "& .MuiDrawer-paper": {
      width: "30%",
    },
  },
  [theme.breakpoints.up("lg")]: {
    width: "25%",
    "& .MuiDrawer-paper": {
      width: "25%",
    },
  },
  [theme.breakpoints.up("xl")]: {
    width: "20%",
    "& .MuiDrawer-paper": {
      width: "20%",
    },
  },
}));

/**
 * @description Main App Layout component used after user authentication
 * @author Jacob Cutshall
 */
const AppLayout = memo(() => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const showDrawer = useAppSelector(selectShowDrawer);
  const showUnauthorizedSiteAccess = useAppSelector(
    selectShowUnauthorizedSiteAccess,
  );
  const dispatch = useAppDispatch();

  const { ref } = useScrollRestoration(location.key, {
    debounceTime: 200,
    persist: "sessionStorage",
  });

  // Handles Refreshing the Auth Token if the user interacts within the last 5 minutes it is valid
  useTokenExpirationHandler();

  // should probably make the drawer state local so it doesn't persist between login/logout
  const handleToggleDrawer = () => {
    dispatch(toggleDrawer());
  };

  const drawerRef = useRef<HTMLDivElement>(null);

  return (
    <HomeContainer aria-label="Home Container">
      <MenuDrawer
        open={showDrawer}
        onClose={handleToggleDrawer}
        anchor="left"
        variant={mobileScreen ? "temporary" : "persistent"}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            boxShadow: "none",
            backgroundImage: "none",
          },
        }}
        ref={drawerRef}
      >
        <Menu isMobileScreen={mobileScreen} />
      </MenuDrawer>

      <Main ref={ref} open={showDrawer} aria-label="Content">
        <ContentGrid>
          <ContentGridWrapper>
            <Header />

            <Outlet />
          </ContentGridWrapper>
        </ContentGrid>
      </Main>

      <ExpiredStoresDialog />

      {showUnauthorizedSiteAccess && <UnauthorizedSiteAccessDialog />}
    </HomeContainer>
  );
});

export default AppLayout;
