import { RootState } from "../../app/store";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie, setCookie } from "../../utils/cookieHandlers";

export type SitesState = {
  filteredSiteOperators: string[];
};

export const getOperatorsFilterCookie = (cookieName: string) => {
  const cookieValUnparsed = getCookie(cookieName);
  if (cookieValUnparsed) {
    const hiddenOperators = cookieValUnparsed.split("~");
    return hiddenOperators;
  }

  return [];
};

const setOperatorFilterCookie = (cookieName: string, operators: string[]) => {
  setCookie(cookieName, operators.join("~"));
};

const initialState: SitesState = {
  filteredSiteOperators: [],
};

export const initFilterOperatorsThunk = createAsyncThunk<
  { username: string },
  undefined,
  { state: RootState }
>("sites/initFilterOperatorsThunk", async (ignoredArg, { getState }) => {
  return { username: getState().auth.username };
});

export const toggleFilterOperatorThunk = createAsyncThunk<
  { operator: string; username: string },
  string,
  { state: RootState }
>("sites/toggleFilterOperatorThunk", async (operator, { getState }) => {
  return { operator, username: getState().auth.username };
});

export const setOperatorsFilterThunk = createAsyncThunk<
  { operators: string[]; username: string },
  string[],
  { state: RootState }
>("sites/setOperatorsFilterThunk", async (operators, { getState }) => {
  return { operators, username: getState().auth.username };
});

export const resetOperatorsFilterThunk = createAsyncThunk<
  { username: string },
  undefined,
  { state: RootState }
>("sites/resetOperatorsFilterThunk", async (ignoredArg, { getState }) => {
  return { username: getState().auth.username };
});

export const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    toggleHideChain: (state, action: PayloadAction<string>) => {
      const hiddenChainsCopy = state.filteredSiteOperators.concat();
      if (state.filteredSiteOperators.includes(action.payload)) {
        // remove
        const index = hiddenChainsCopy.findIndex((v) => v === action.payload);
        if (index > -1) {
          hiddenChainsCopy.splice(index, 1);
          state.filteredSiteOperators = hiddenChainsCopy;
        }
      } else {
        //add
        hiddenChainsCopy.push(action.payload);
        state.filteredSiteOperators = hiddenChainsCopy;
      }
    },
    setHiddenChains: (state, action: PayloadAction<string[]>) => {
      state.filteredSiteOperators = action.payload;
    },
    resetHiddenChains: (state) => {
      state.filteredSiteOperators = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(initFilterOperatorsThunk.fulfilled, (state, action) => {
      if (action.payload.username !== "") {
        const filteredOperators = getOperatorsFilterCookie(
          `hiddenChains~${action.payload.username}`,
        );
        state.filteredSiteOperators = filteredOperators;
      }
    }),
      builder.addCase(toggleFilterOperatorThunk.fulfilled, (state, action) => {
        const hiddenChainsCopy = state.filteredSiteOperators.concat();
        if (state.filteredSiteOperators.includes(action.payload.operator)) {
          // remove
          const index = hiddenChainsCopy.findIndex(
            (v) => v === action.payload.operator,
          );
          if (index > -1) {
            hiddenChainsCopy.splice(index, 1);
            state.filteredSiteOperators = hiddenChainsCopy;
          }
        } else {
          //add
          hiddenChainsCopy.push(action.payload.operator);
          state.filteredSiteOperators = hiddenChainsCopy;
        }

        if (action.payload.username !== "") {
          setOperatorFilterCookie(
            `hiddenChains~${action.payload.username}`,
            hiddenChainsCopy,
          );
        }
      }),
      builder.addCase(setOperatorsFilterThunk.fulfilled, (state, action) => {
        if (action.payload.username !== "") {
          setOperatorFilterCookie(
            `hiddenChains~${action.payload.username}`,
            action.payload.operators,
          );
        }
        state.filteredSiteOperators = action.payload.operators;
      }),
      builder.addCase(resetOperatorsFilterThunk.fulfilled, (state, action) => {
        if (action.payload.username !== "") {
          setOperatorFilterCookie(
            `hiddenChains~${action.payload.username}`,
            [],
          );
        }
        state.filteredSiteOperators = [];
      });
  },
});

export const { toggleHideChain, setHiddenChains, resetHiddenChains } =
  sitesSlice.actions;

export const selectFilteredSiteOperators = (state: RootState) =>
  state.sites.filteredSiteOperators;

export default sitesSlice.reducer;

// startAppListening({
//     actionCreator: initFilterOperatorsThunk.fulfilled,
//     effect: (action, listenerApi) => {
//         console.log("Running listener effect")
//         // listen for fulfilled on init
//         // handle setting it on init
//         // then run function to set the sitesList
//         if (action.payload.username !== '') {
//             const state = listenerApi.getState();
//             const filteredOperators = getOperatorsFilterCookie(
//                 `hiddenChains~${action.payload.username}`,
//             );
//             state.sites.filteredSiteOperators = filteredOperators;
//         }
//     },
// });
