import { DateTime } from "luxon";

export function convertIsoToDateTimeStringUTC(isoString: string) {
  return DateTime.fromISO(isoString, { zone: "utc" }).toFormat(
    "MM/dd/yy HH:mm",
  );
}

export default function convertIsoToDateTimeString(
  isoString: string,
  options?: {
    twelveHourFormat?: boolean;
    zone?: string;
  },
) {
  const { twelveHourFormat = false, zone = "system" } = options ? options : {};

  const dateObj = DateTime.fromISO(isoString, { zone: "utc" }).setZone(zone);

  if (twelveHourFormat) {
    return dateObj.toLocaleString(DateTime.DATETIME_SHORT);
  }

  return dateObj.toFormat("MM/dd/yy HH:mm");
}

export function convertIsoToDate(isoString: string, zone = "utc") {
  return DateTime.fromISO(isoString, { zone }).toFormat("MM/dd/yy");
}

export function convertIsoToLocalDateTimeString(
  isoString: string,
  twelveHourFormat?: boolean,
) {
  if (twelveHourFormat) {
    return DateTime.fromISO(isoString, { zone: "utc" })
      .setZone("system")
      .toLocaleString(DateTime.DATETIME_SHORT);
  } else {
    return DateTime.fromISO(isoString, { zone: "utc" })
      .setZone("system")
      .toFormat("MM/dd/yy HH:mm");
  }
}

export function convertJSDateToLocalDateTimeString(
  jsDate: Date,
  twelveHourFormat?: boolean,
) {
  if (twelveHourFormat) {
    return DateTime.fromJSDate(jsDate).toLocaleString(DateTime.DATETIME_SHORT);
  } else {
    return DateTime.fromJSDate(jsDate).toFormat("MM/dd/yy HH:mm");
  }
}
