import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { authApi } from "./authAPI";
import jwtDecode from "jwt-decode";
import { UserType } from "../users/usersAPI";
import { DateTime } from "luxon";
import { startAppListening } from "../../app/listenerMiddleware";
import { GetSitePermissionsResponse, sitesApi } from "../sites/sitesAPI";
import { createSelector, type Draft } from "@reduxjs/toolkit";
import { SiteType } from "../sites/sitesAPI";

export enum Permissions {
  AdminUser = "admin_user",
  SiteManagerUser = "site_manager_user",
  EditOtherUsers = "edit_other_user_permission",
  ReceiveLeoStatusEmail = "receive_leo_status_email",
  OptLeoStatusEmail = "leo_status_email",
  ReceiveAnalyticReportEmails = "receive_analytic_report_emails",
  OptRackUsageEmail = "rack_usage_email",
  OptLiquidLevelLeakEmail = "liquid_level_leak_email",
  OptCaseTempEmail = "case_temp_email",
  RemoteAccessLeo = "remote_access_leo",
  RemoteAccessController = "remote_access_controller",
  ViewFloorplan = "view_floor_plan",
  EditFloorplan = "edit_floor_plan",
  ViewStore = "view_store",
  EditPhoneNumbers = "edit_phone_number",
}

export interface DecodedToken {
  user_id: number;
  username: string;
  admin_type: UserType;
  store_list: number[];
  store_permissions: {
    [siteId: number]: number[];
  };
  user_permissions: null; // this will change

  minimal: false;
  exp: number;
  iat: number;
}
export interface MinimalDecodedToken {
  user_id: number;
  admin_type: UserType;
  exp: number;
  iat: number;
  minimal: true;
  store_list: never[];
  store_permissions: never[];
  user_permissions: never[];
  username: string;
}

export interface AuthState {
  isAuthenticated: boolean;
  hasTokenAwaitingTwoFactor: boolean;
  username: string;
  userId: number;
  token: string;
  adminType: UserType;
  siteList: number[];
  filteredSiteList: number[];
  setFilteredSitesList: boolean;
  sitePermissionsList: GetSitePermissionsResponse;
  userSitesPermissions: {
    [siteId: number]: number[];
  };
}

const AddedSitesKey = "addedSites";

function getInitialState(): AuthState {
  const token = localStorage.getItem("token");
  const minToken = localStorage.getItem("minToken");
  const awaitingTwoFactor = localStorage.getItem("awaitingTwoFactor");
  if (token) {
    const decoded = jwtDecode(token) as DecodedToken;

    console.log({ decodedInit: decoded });
    // Check the decoded.exp time
    const nowSecs = DateTime.now().toUTC().toUnixInteger();
    const expiresIn = ((decoded.exp - nowSecs) / 60).toFixed(2);
    console.info(
      `getInitialState: token expires in %c${expiresIn}%cminutes`,
      "font-weight: bold; color: yellow;",
    );

    if (nowSecs >= decoded.exp) {
      console.info(
        "%clogging out because token expired via init check (checking stored token on first load)",
        "color: orange",
      );
      localStorage.removeItem("token");
    } else {
      decoded.store_list = decoded.store_list.map((v) => +v);
      return {
        token,
        isAuthenticated: true,
        hasTokenAwaitingTwoFactor: false,
        username: decoded.username,
        userId: decoded.user_id,
        adminType: decoded.admin_type,
        siteList: decoded.store_list,
        filteredSiteList: [], //decoded.store_list, // need to check early for filtered list
        setFilteredSitesList: false,
        sitePermissionsList: [],
        userSitesPermissions: decoded.store_permissions,
      };
    }
  } else if (minToken && awaitingTwoFactor) {
    const minDecoded = jwtDecode(minToken) as MinimalDecodedToken;
    const nowSecs = DateTime.now().toUTC().toUnixInteger();
    const expiresIn = ((minDecoded.exp - nowSecs) / 60).toFixed(2);
    console.log("minToken Expires In: ", expiresIn);
    if (nowSecs >= minDecoded.exp) {
      // remove all credentials
      localStorage.removeItem("minToken");
      localStorage.removeItem("awaitingTwoFactor");
    } else {
      return {
        token: minToken,
        isAuthenticated: false,
        hasTokenAwaitingTwoFactor: true,
        username: minDecoded.username,
        userId: minDecoded.user_id,
        adminType: minDecoded.admin_type,
        siteList: minDecoded.store_list,
        filteredSiteList: [],
        setFilteredSitesList: false,
        sitePermissionsList: [],
        userSitesPermissions: minDecoded.store_permissions,
      };
    }
  }

  return {
    isAuthenticated: false,
    hasTokenAwaitingTwoFactor: false,
    token: "",
    adminType: "user",
    username: "",
    userId: 0,
    siteList: [],
    filteredSiteList: [],
    setFilteredSitesList: false,
    sitePermissionsList: [],
    userSitesPermissions: {},
  };
}

function handleCredentials(state: Draft<AuthState>, token: string) {
  const decoded = jwtDecode(token) as DecodedToken;
  const nowSecs = DateTime.now().toUTC().toUnixInteger();
  const expiresIn = ((decoded.exp - nowSecs) / 60).toFixed(2);
  console.info(
    `credentialsSet: token expires in %c${expiresIn}%cminutes`,
    "font-weight: bold; color: yellow;",
  );

  console.log({ decoded });

  localStorage.setItem("token", token);
  localStorage.removeItem("minToken");
  localStorage.removeItem("awaitingTwoFactor");

  // convert store list (Array of Store ID's) to INTEGERS
  decoded.store_list = decoded.store_list.map((v) => +v);

  state.isAuthenticated = true;
  state.hasTokenAwaitingTwoFactor = false;
  state.token = token;
  state.username = decoded.username;
  state.userId = decoded.user_id;
  state.adminType = decoded.admin_type;
  state.siteList = decoded.store_list;
  state.filteredSiteList = [];
  state.userSitesPermissions = decoded.store_permissions;
}

const initialState: AuthState = getInitialState();

// should introduce a async Thunk to handle Logging in
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (_state) => {
      localStorage.removeItem("token");
      return {
        isAuthenticated: false,
        hasTokenAwaitingTwoFactor: false,
        token: "",
        username: "",
        userId: 0,
        adminType: "user",
        siteList: [],
        filteredSiteList: [],
        setFilteredSitesList: false,
        userPermissions: {
          terminalMode: false,
          showLEO: false,
          editFloorplan: false,
        },
        sitePermissionsList: _state.sitePermissionsList,
        userSitesPermissions: {},
      };
    }, // we will have to create a new action here
    setMinimalToken: (
      state,
      {
        payload: { token: minToken, awaitingTwoFactor },
      }: PayloadAction<{ token: string; awaitingTwoFactor?: boolean }>,
    ) => {
      const decoded = jwtDecode(minToken) as MinimalDecodedToken;

      state.token = minToken;
      state.username = decoded.username;
      if (awaitingTwoFactor) {
        state.hasTokenAwaitingTwoFactor = true;
        localStorage.setItem("minToken", minToken);
        // I may want to save a timestamp so I can invalidate this in 10 minutes
        localStorage.setItem("awaitingTwoFactor", true.toString());
      }
      // maybe save this in localStorage to facilitate 2Factor autoFill from email
    },
    setCredentials: (
      state,
      { payload: { token } }: PayloadAction<{ token: string }>,
    ) => {
      handleCredentials(state, token);
    },
    updateFilteredSiteList: (state, action: PayloadAction<number[]>) => {
      state.setFilteredSitesList = true;
      state.filteredSiteList = action.payload;
    },
    addNewSiteIdToSiteList: (state, action: PayloadAction<number>) => {
      state.siteList.push(action.payload);
      const sitesAddedStr = localStorage.getItem(AddedSitesKey);
      if (sitesAddedStr) {
        try {
          const sitesAdded = JSON.parse(sitesAddedStr) as number[];
          sitesAdded.push(action.payload);
          localStorage.setItem(AddedSitesKey, JSON.stringify(sitesAdded));
        } catch (err) {
          console.warn(
            "Failed to update addedSites in localStorage probably because invalid JSON",
          );
          console.error(err);
        }
      } else {
        localStorage.setItem(AddedSitesKey, JSON.stringify([action.payload]));
      }
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      authApi.endpoints.twoFactor.matchFulfilled,
      (state, { payload: { token } }) => {
        handleCredentials(state, token);
      },
    );
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload: { token } }) => {
        const decodedToken = jwtDecode(token) as
          | DecodedToken
          | MinimalDecodedToken;
        // if the token is not minimal after login we want to set the credentials because they are authorized
        if (!decodedToken.minimal) {
          handleCredentials(state, token);
        }
      },
    );
    builder.addMatcher(
      authApi.endpoints.refreshToken.matchFulfilled,
      (state, { payload: { token } }) => {
        handleCredentials(state, token);
      },
    );
    builder.addMatcher(
      authApi.endpoints.updateToken.matchFulfilled,
      (state, { payload: { token } }) => {
        handleCredentials(state, token);
      },
    );
    builder.addMatcher(
      sitesApi.endpoints.getSitesPermissions.matchFulfilled,
      (state, { payload }) => {
        console.info("%cPermission List set", "color: green");
        state.sitePermissionsList = payload; // I want to sort ther permissions so they are displayed in a certain way (required_permission comes before the permission that requires it)
      },
    );
  },
});

export const {
  logout,
  setMinimalToken,
  setCredentials,
  updateFilteredSiteList,
  addNewSiteIdToSiteList,
} = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;
export const selectAuthToken = (state: RootState) => state.auth.token;
export const selectAdminType = (state: RootState) => state.auth.adminType;

export const selectUsername = (state: RootState) => state.auth.username;
export const selectUserId = (state: RootState) => state.auth.userId;
export const selectSiteList = (state: RootState) => state.auth.siteList;

export const selectHasSetFilteredSiteList = (state: RootState) =>
  state.auth.setFilteredSitesList;
export const selectFilteredSiteList = (state: RootState) =>
  state.auth.filteredSiteList;

export const selectAuthChainInfo = (state: RootState) => ({
  adminType: state.auth.adminType,
});

export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;

// USER PERMISSIONS
export const selectIsRegularUser = (state: RootState) =>
  state.auth.adminType === "user";
export const selectIsChainAdmin = (state: RootState) =>
  state.auth.adminType === "admin";
export const selectIsGlobalAdmin = (state: RootState) =>
  state.auth.adminType === "global";

export const selectCanViewAdminPortal = (state: RootState) => {
  return state.auth.adminType === "global" || state.auth.adminType === "admin";
};
export const selectCanViewAnalytics = (state: RootState) => {
  return state.auth.adminType === "global" || state.auth.adminType === "admin";
};
export const selectCanDeleteController = (state: RootState) => {
  return state.auth.adminType === "global" || state.auth.adminType === "admin";
};

// TODO
// I should change the wording of this to just "PERMISSIONS"
export const selectSitePermissionsList = (state: RootState) => {
  return state.auth.sitePermissionsList;
};

export const selectAdminUserPermission = (state: RootState) => {
  return state.auth.sitePermissionsList.find(
    (p) => p.permission_name === Permissions.AdminUser,
  );
};
export const selectSiteManagerUserPermission = (state: RootState) => {
  return state.auth.sitePermissionsList.find(
    (p) => p.permission_name === Permissions.SiteManagerUser,
  );
};

//TODO
// Chnage wording to UserPermissions
export const selectUserSitesPermissions = (state: RootState) => {
  return state.auth.userSitesPermissions;
};

// used internally for the Derived Selectors
const selectSiteId = (state: RootState, siteId: number) => siteId;
const selectSiteFromCache = (state: RootState, siteId: number) => {
  const keys = Object.keys(state.api.queries);
  const getSitesKey = keys.find((k) => k.includes("getSites("));
  if (getSitesKey) {
    const sites = state.api.queries[getSitesKey]?.data as SiteType[];
    return sites.find((s) => s.store_num === siteId);
  }
};

// Derived Selectors
export const selectCanReceiveLeoStatusEmailsForSite = createSelector(
  [selectSitePermissionsList, selectUserSitesPermissions, selectSiteId],
  (permissionsList, sitesPermissions, siteId) => {
    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.ReceiveLeoStatusEmail,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);
export const selectOptInLeoStatusEmailsForSite = createSelector(
  [selectSitePermissionsList, selectUserSitesPermissions, selectSiteId],
  (permissionsList, sitesPermissions, siteId) => {
    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.OptLeoStatusEmail,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);
export const selectCanReceiveAnalyticsReportEmailsForSite = createSelector(
  [selectSitePermissionsList, selectUserSitesPermissions, selectSiteId],
  (permissionsList, sitesPermissions, siteId) => {
    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.ReceiveAnalyticReportEmails,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);
export const selectOptInRackUsageEmailsForSite = createSelector(
  [selectSitePermissionsList, selectUserSitesPermissions, selectSiteId],
  (permissionsList, sitesPermissions, siteId) => {
    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.OptRackUsageEmail,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);
export const selectOptInLiquidLevelEmailsForSite = createSelector(
  [selectSitePermissionsList, selectUserSitesPermissions, selectSiteId],
  (permissionsList, sitesPermissions, siteId) => {
    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.OptLiquidLevelLeakEmail,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);
export const selectOptInCaseTempEmailsForSite = createSelector(
  [selectSitePermissionsList, selectUserSitesPermissions, selectSiteId],
  (permissionsList, sitesPermissions, siteId) => {
    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.OptCaseTempEmail,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);

export const selectCanRemoteAccessLeoForSite = createSelector(
  [
    selectIsGlobalAdmin,
    selectSitePermissionsList,
    selectUserSitesPermissions,
    selectSiteId,
  ],
  (isGlobalAdmin, permissionsList, sitesPermissions, siteId) => {
    if (isGlobalAdmin) return true;

    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.RemoteAccessLeo,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);
export const selectCanRemoteAccessControllerForSite = createSelector(
  [
    selectIsGlobalAdmin,
    selectSitePermissionsList,
    selectUserSitesPermissions,
    selectSiteId,
  ],
  (isGlobalAdmin, permissionsList, sitesPermissions, siteId) => {
    if (isGlobalAdmin) return true;

    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.RemoteAccessController,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);

export const selectCanViewFloorplanForSite = createSelector(
  [
    selectIsGlobalAdmin,
    selectSitePermissionsList,
    selectUserSitesPermissions,
    selectSiteId,
  ],
  (isGlobalAdmin, permissionsList, sitesPermissions, siteId) => {
    if (isGlobalAdmin) return true;

    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.ViewFloorplan,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);
export const selectCanEditFloorplanForSite = createSelector(
  [
    selectIsGlobalAdmin,
    selectSitePermissionsList,
    selectUserSitesPermissions,
    selectSiteId,
  ],
  (isGlobalAdmin, permissionsList, sitesPermissions, siteId) => {
    if (isGlobalAdmin) return true;

    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.EditFloorplan,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);

export const selectIsAdminForSite = createSelector(
  [
    selectSitePermissionsList,
    selectUserSitesPermissions,
    selectIsGlobalAdmin,
    selectSiteId,
  ],
  (permissionsList, sitesPermissions, isGlobalAdmin, siteId) => {
    // admin_users has some limitations and we don't want to apply those limitations if the user is global
    if (isGlobalAdmin) return false;

    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.AdminUser,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);
export const selectIsSiteManagerForSite = createSelector(
  [
    selectSitePermissionsList,
    selectUserSitesPermissions,
    selectIsGlobalAdmin,
    selectIsAdminForSite,
    selectSiteId,
  ],
  (
    permissionsList,
    sitesPermissions,
    isGlobalAdmin,
    isAdminForSite,
    siteId,
  ) => {
    if (isGlobalAdmin || isAdminForSite) return false;

    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.SiteManagerUser,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);

export const selectCanEditUserPermissionsForSite = createSelector(
  [
    selectIsGlobalAdmin,
    selectSitePermissionsList,
    selectUserSitesPermissions,
    selectSiteId,
  ],
  (isGlobalAdmin, permissionsList, sitesPermissions, siteId) => {
    if (isGlobalAdmin) return true;

    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.EditOtherUsers,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);
// I believe we can get away with using the selector above becuase that permission means the same thing
export const selectCanAddOrEditUsersForSite = createSelector(
  [
    selectIsAdminForSite,
    selectCanEditUserPermissionsForSite,
    selectIsGlobalAdmin,
  ],
  (isAdmin, hasEditPermission, isGlobalAdmin) => {
    return isAdmin || hasEditPermission || isGlobalAdmin;
  },
);

export const selectCanEditSiteInfo = createSelector(
  [selectIsAdminForSite, selectIsGlobalAdmin],
  (isAdminForSite, isGlobalAdmin) => {
    return isAdminForSite || isGlobalAdmin;
  },
);

export const selectCanChangePhoneNumbersForSite = createSelector(
  [
    selectIsGlobalAdmin,
    selectSitePermissionsList,
    selectUserSitesPermissions,
    selectSiteId,
  ],
  (isGlobalAdmin, permissionsList, sitesPermissions, siteId) => {
    if (isGlobalAdmin) return true;

    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.EditPhoneNumbers,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);

export const selectCanViewSite = createSelector(
  [
    selectIsGlobalAdmin,
    selectSitePermissionsList,
    selectUserSitesPermissions,
    selectSiteId,
  ],
  (isGlobalAdmin, permissionsList, sitesPermissions, siteId) => {
    if (isGlobalAdmin) return true;

    const permission = permissionsList.find(
      (p) => p.permission_name === Permissions.ViewStore,
    );
    if (!permission) return false;

    const sitePermissions = sitesPermissions?.[siteId] ?? [];
    if (sitePermissions?.length === 0) return false;

    return sitePermissions.includes(permission.permission_id);
  },
);

export const selectUserEditablePermissions = createSelector(
  [
    selectSitePermissionsList,
    selectCanReceiveAnalyticsReportEmailsForSite,
    selectCanReceiveLeoStatusEmailsForSite,
  ],
  (permissions, canReceiveAnalyticsEmails, canReceiveLeoEmails) => {
    const perms: GetSitePermissionsResponse = [];
    if (canReceiveLeoEmails) {
      const opt_leo_status_email_permission = permissions.find(
        (p) => p.permission_name === Permissions.OptLeoStatusEmail,
      );
      if (opt_leo_status_email_permission)
        perms.push(opt_leo_status_email_permission);
    }

    if (canReceiveAnalyticsEmails) {
      const optRackUsageEmails = permissions.find(
        (p) => p.permission_name === Permissions.OptRackUsageEmail,
      );
      const optLiquidLevelEmails = permissions.find(
        (p) => p.permission_name === Permissions.OptLiquidLevelLeakEmail,
      );
      const optCaseTempsEmails = permissions.find(
        (p) => p.permission_name === Permissions.OptCaseTempEmail,
      );

      if (optRackUsageEmails) perms.push(optRackUsageEmails);
      if (optLiquidLevelEmails) perms.push(optLiquidLevelEmails);
      if (optCaseTempsEmails) perms.push(optCaseTempsEmails);
    }
    return perms;
  },
);

export const selectDefaultPermissionsForUserTypeAdmin = createSelector(
  [selectSitePermissionsList],
  (permissions) => {
    const defaultPermissions = [];

    const view_store_permission = permissions.find(
      (p) => p.permission_name === Permissions.ViewStore,
    );
    const admin_user_permission = permissions.find(
      (p) => p.permission_name === Permissions.AdminUser,
    );
    const edit_other_user_permission = permissions.find(
      (p) => p.permission_name === Permissions.EditOtherUsers,
    );
    const receive_leo_status_email_permission = permissions.find(
      (p) => p.permission_name === Permissions.ReceiveLeoStatusEmail,
    );
    const opt_leo_status_email_permission = permissions.find(
      (p) => p.permission_name === Permissions.OptLeoStatusEmail,
    );

    if (view_store_permission) defaultPermissions.push(view_store_permission);
    if (admin_user_permission) defaultPermissions.push(admin_user_permission);
    if (edit_other_user_permission)
      defaultPermissions.push(edit_other_user_permission);
    if (receive_leo_status_email_permission)
      defaultPermissions.push(receive_leo_status_email_permission);
    if (opt_leo_status_email_permission)
      defaultPermissions.push(opt_leo_status_email_permission);

    return defaultPermissions;
  },
);

function removePermissionsRelatedToSiteFeatures(
  permissionList: GetSitePermissionsResponse,
  floorplanAvailable: boolean,
  terminalModeAvailable: boolean,
  leoAlertAvailable: boolean,
) {
  const permissions = permissionList.filter((p) => {
    if (p.permission_name === Permissions.EditFloorplan && !floorplanAvailable)
      return false;
    if (p.permission_name === Permissions.ViewFloorplan && !floorplanAvailable)
      return false;
    if (
      p.permission_name === Permissions.RemoteAccessLeo &&
      !terminalModeAvailable
    )
      return false;
    if (
      p.permission_name === Permissions.RemoteAccessController &&
      !terminalModeAvailable
    )
      return false;

    if (
      p.permission_name === Permissions.EditPhoneNumbers &&
      !leoAlertAvailable
    )
      return false;

    return true;
  });
  return permissions;
}

// TODO
/** TODO
 * REDO
 * NEEDS IMPROVEMENT
 * @description This is a important selector for our Site-Users Logic
 * So we don't reveal any hidden permissions that may be available for other sites/chains
 *
 */
export const selectFilteredPermissionsListForSiteBasedOnUsers = createSelector(
  [
    selectSitePermissionsList,
    selectUserSitesPermissions,
    selectIsGlobalAdmin,
    selectIsAdminForSite,
    selectIsSiteManagerForSite,
    selectCanReceiveLeoStatusEmailsForSite,
    selectCanReceiveAnalyticsReportEmailsForSite,
    selectSiteId,
    selectSiteFromCache,
  ],
  (
    permissionsList,
    userSitesPermissions,
    isGlobalAdmin,
    isAdminForSite,
    isSiteManager,
    canReceiveLeoStatusEmails,
    canReceiveAnalyticsReportEmails,
    siteId,
    site,
  ) => {
    let floorplanAvailable = true;
    let terminalModeAvailable = true;
    let leoAlertAvailable = true;
    if (site) {
      floorplanAvailable = site.floor_plan_enabled;
      terminalModeAvailable = site.terminal_mode_enabled;
      leoAlertAvailable = site.voice_sms_subscription !== null;
    }
    // It was described to me that a user could give any of their permissions to other users
    // But this is actually not the case...
    // Admin User can't give admin_user permission but can give store_manager which it is missing
    // Store Manager Users can't create other Store_manager Users even though they have the permission

    // Global users should be able to give every permission (Besides admin_user <- handled by Admin Creation flow)
    if (isGlobalAdmin) {
      const admin_user_permission = permissionsList.find(
        (p) => p.permission_name === Permissions.AdminUser,
      );
      const adminUserIndex = permissionsList.findIndex(
        (p) => p.permission_id === admin_user_permission?.permission_id,
      );
      const permissionsListCopy = structuredClone(permissionsList);
      if (adminUserIndex > -1) permissionsListCopy.splice(adminUserIndex, 1); // REMOVE admin_user (This will be handled by the API)
      //return permissionsListCopy;
      return removePermissionsRelatedToSiteFeatures(
        permissionsListCopy,
        floorplanAvailable,
        terminalModeAvailable,
        leoAlertAvailable,
      );
    }
    /*
            1. Admin_User needs store_manager permission available
            2.
         */

    const userPermissions = structuredClone(userSitesPermissions[siteId]) as
      | number[]
      | undefined;

    if (!userPermissions) {
      return [];
    }

    if (isAdminForSite) {
      // add store_manager related permissions
      // remove admin_user permission

      // ADD
      const site_manager_permission = permissionsList.find(
        (p) => p.permission_name === Permissions.SiteManagerUser,
      );
      // ADD
      const edit_user_permission = permissionsList.find(
        (p) => p.permission_name === Permissions.EditOtherUsers,
      );
      // ADD (CHECK IF USER HAS THIS FLOOR PLAN AVAILABLE)
      const edit_floorplan_permission = permissionsList.find(
        (p) => p.permission_name === Permissions.EditFloorplan,
      );
      // REMOVE
      const admin_user_permission = permissionsList.find(
        (p) => p.permission_name === Permissions.AdminUser,
      );

      const adminUserIndex = userPermissions.findIndex(
        (p) => p === admin_user_permission?.permission_id,
      );
      if (adminUserIndex > -1) {
        userPermissions.splice(adminUserIndex, 1);
      }

      if (
        site_manager_permission &&
        !userPermissions.includes(site_manager_permission.permission_id)
      )
        userPermissions.push(site_manager_permission.permission_id);

      if (
        edit_user_permission &&
        !userPermissions.includes(edit_user_permission.permission_id)
      )
        userPermissions.push(edit_user_permission.permission_id);

      if (
        edit_floorplan_permission &&
        !userPermissions.includes(edit_floorplan_permission.permission_id)
      )
        userPermissions.push(edit_floorplan_permission.permission_id);
    }

    if (isSiteManager) {
      // remove Site Manager Permissions ("site_manager_user", "edit_users_permission")
      const site_manager_permission = permissionsList.find(
        (p) => p.permission_name === Permissions.SiteManagerUser,
      );
      // remove
      const edit_user_permission = permissionsList.find(
        (p) => p.permission_name === Permissions.EditOtherUsers,
      );
      // remove
      // const edit_floorplan_permission = permissionsList.find(
      //   (p) => p.permission_name === Permissions.EditFloorplan,
      // );

      const siteManagerIndex = userPermissions.findIndex(
        (p) => p === site_manager_permission?.permission_id,
      );
      if (siteManagerIndex > -1) {
        userPermissions.splice(siteManagerIndex, 1);
      }
      const editUserIndex = userPermissions.findIndex(
        (p) => p === edit_user_permission?.permission_id,
      );
      if (editUserIndex > -1) {
        userPermissions.splice(editUserIndex, 1);
      }
      // const editFloorplanIndex = userPermissions.findIndex(
      //   (p) => p === edit_floorplan_permission?.permission_id,
      // );
      // if (editFloorplanIndex > -1) {
      //   userPermissions.splice(editFloorplanIndex, 1);
      // }
    }

    if (canReceiveLeoStatusEmails) {
      // add opt_leo_status_emails
      const optLeoStatusEmails = permissionsList.find(
        (p) => p.permission_name === Permissions.OptLeoStatusEmail,
      );

      if (
        optLeoStatusEmails &&
        !userPermissions.includes(optLeoStatusEmails.permission_id)
      )
        userPermissions.push(optLeoStatusEmails.permission_id);
    }

    if (canReceiveAnalyticsReportEmails) {
      // add opt_leo_status_emails
      const optRackUsageEmails = permissionsList.find(
        (p) => p.permission_name === Permissions.OptRackUsageEmail,
      );
      const optLiquidLevelEmails = permissionsList.find(
        (p) => p.permission_name === Permissions.OptLiquidLevelLeakEmail,
      );
      const optCaseTempsEmails = permissionsList.find(
        (p) => p.permission_name === Permissions.OptCaseTempEmail,
      );

      if (
        optRackUsageEmails &&
        !userPermissions.includes(optRackUsageEmails.permission_id)
      ) {
        userPermissions.push(optRackUsageEmails.permission_id);
      }

      if (
        optLiquidLevelEmails &&
        !userPermissions.includes(optLiquidLevelEmails.permission_id)
      ) {
        userPermissions.push(optLiquidLevelEmails.permission_id);
      }

      if (
        optCaseTempsEmails &&
        !userPermissions.includes(optCaseTempsEmails.permission_id)
      ) {
        userPermissions.push(optCaseTempsEmails.permission_id);
      }
    }

    const filteredList = permissionsList.filter((perm) => {
      return userPermissions.includes(perm.permission_id);
    });

    // return filteredList;
    return removePermissionsRelatedToSiteFeatures(
      filteredList,
      floorplanAvailable,
      terminalModeAvailable,
      leoAlertAvailable,
    );
  },
);

export default authSlice.reducer;

startAppListening({
  predicate: (_action, currState) => {
    const { data } = sitesApi.endpoints.getSites.select({
      store_list: currState.auth.siteList,
    })(currState);

    const filteredSiteOperatorsLength =
      currState.sites.filteredSiteOperators?.length;
    if (filteredSiteOperatorsLength > 0 && data) {
      return true;
    } else if (
      filteredSiteOperatorsLength === 0 &&
      data &&
      currState.auth.filteredSiteList?.length < currState.auth.siteList.length
    ) {
      return true;
    }
    return false;
  },
  effect: (_action, listenerApi) => {
    const state = listenerApi.getState();

    const { data } = sitesApi.endpoints.getSites.select({
      store_list: state.auth.siteList,
    })(state);

    const filteredSiteOperatorsLength =
      state.sites.filteredSiteOperators?.length;

    if (
      filteredSiteOperatorsLength > 0 &&
      data &&
      state.auth.filteredSiteList
    ) {
      const myFilteredSiteList = structuredClone(data).filter((site) => {
        return !state.sites.filteredSiteOperators.includes(
          site.store_operator ?? "No Group",
        );
      });

      if (
        Array.from(myFilteredSiteList)
          .map((v) => v.store_num)
          .sort()
          .join(",") !==
        Array.from(state.auth.filteredSiteList)?.sort()?.join(",")
      ) {
        listenerApi.dispatch(
          updateFilteredSiteList(myFilteredSiteList.map((v) => v.store_num)),
        );
      }
    } else if (
      filteredSiteOperatorsLength === 0 &&
      state.auth.filteredSiteList?.length < state.auth.siteList.length
    ) {
      listenerApi.dispatch(updateFilteredSiteList(state.auth.siteList));
    }
  },
});
