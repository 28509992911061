import { SyntheticEvent, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import ActiveAlarmsTable from "../../components/Alarms/ActiveAlarmsTable";
import HistoricalAlarmsTable from "../../components/Alarms/HistoricalAlarmsTable";
import OverrideAlarmsTable from "../../components/Alarms/OverrideAlarmsTable";
import {
  AllSitesAlarmType,
  useGetAlarmsForStoresQuery,
} from "../../features/alarms/alarmsAPI";
import {
  useAppSelector,
  useSelectFilteredSitesSelector,
} from "../../app/hooks";
import {
  selectFilteredSiteList,
  selectSiteList,
  selectHasSetFilteredSiteList,
} from "../../features/auth/authSlice";
import { useGetSitesQuery } from "../../features/sites/sitesAPI";
import {
  nonFilteredAlarmStrings,
  deviceAbsentValidApps,
} from "../Site/SiteAlarms/SiteAlarms";
import useHandleUpdateUrlWithTabValue from "../../hooks/appHooks/useHandleUpdateUrlWithTabValue";
import { DateTime } from "luxon";
import CustomTabPanel from "../../components/Tabs/CustomTabPanel";

export enum AlarmsTabs {
  ACTIVE = "active",
  OVERRIDE = "override",
  HISTORY = "history",
}

type Props = {
  tab?: AlarmsTabs;
};

const AllSiteAlarms = ({ tab }: Props) => {
  const site_list = useAppSelector(selectSiteList);
  const hasSetFilteredSiteList = useAppSelector(selectHasSetFilteredSiteList);
  const filteredSiteList = useAppSelector(selectFilteredSiteList);

  const selectFilteredSites = useSelectFilteredSitesSelector();
  const {
    data: storeData,
    isLoading: isLoadingStores,
    isSuccess,
  } = useGetSitesQuery(
    { store_list: site_list },
    {
      selectFromResult: (result) => ({
        ...result,
        data: selectFilteredSites(result, filteredSiteList),
      }),
    },
  );

  const { data: alarmData, isLoading: isLoadingAlarms } =
    useGetAlarmsForStoresQuery(
      { storeIds: filteredSiteList },
      {
        pollingInterval: 300000,
        skipPollingIfUnfocused: true,
        refetchOnMountOrArgChange: 60,
        skip: !isSuccess || storeData === undefined || !hasSetFilteredSiteList, // I want to prevent this from running until we have our filteredSiteList
      },
    );

  const storeAlarms = useMemo(() => {
    // returns an aarray of alarms for each store
    const obj: {
      active: AllSitesAlarmType[];
      historical: AllSitesAlarmType[];
      override: AllSitesAlarmType[];
    } = {
      active: [],
      historical: [],
      override: [],
    };

    if (storeData && alarmData && alarmData[0]) {
      for (const d of alarmData) {
        for (const alrm of d.alarms) {
          const store = storeData.find((s) => s.store_num === d.store_num);

          const alarmCopy = structuredClone(alrm) as AllSitesAlarmType;
          if (store) {
            alarmCopy.siteName = store.store_name;
            alarmCopy.siteId = store.store_num;
            alarmCopy.siteLocation = `${store.store_city}, ${store.store_state}`;

            alarmCopy.filtered = false;
            const isInglesSite = store.store_operator === "INGLES";
            if (isInglesSite) {
              alarmCopy.filtered = true;
              // check for filtered vals
              if (
                nonFilteredAlarmStrings.includes(
                  alarmCopy.alarm_text.toLowerCase(),
                )
              ) {
                // this is a active alarm to show
                alarmCopy.filtered = false;
              } else if (
                alarmCopy.alarm_text.toLowerCase() ===
                "device absent from network"
              ) {
                for (const val of deviceAbsentValidApps) {
                  if (alarmCopy.alarm_source.toLowerCase().includes(val)) {
                    alarmCopy.filtered = false;
                    break;
                  }
                }
              }
            }

            if (
              alrm.service_override_date !== null &&
              alrm.alarm_active === 1
            ) {
              const now = DateTime.now().setZone("utc");
              const overrideDate = DateTime.fromISO(
                alrm.service_override_date,
              ).setZone("utc");
              if (now >= overrideDate) {
                obj.active.push(alarmCopy);
              } else {
                obj.override.push(alarmCopy);
              }
            } else if (alrm.alarm_active === 1) {
              obj.active.push(alarmCopy);
            } else {
              obj.historical.push(alarmCopy);
            }
          } else {
            // alarmCopy.siteName = 'N/A';
            // alarmCopy.siteId = 0;
            // alarmCopy.siteLocation = 'unknown';
            // dont add alarms we have no site for
          }
        }
      }
    }

    return obj;
  }, [alarmData, storeData]);

  const [tabValue, setTabValue] = useState<AlarmsTabs>(
    tab ? tab : AlarmsTabs.ACTIVE,
  );

  const handleTabChange = (_event: SyntheticEvent, newValue: AlarmsTabs) => {
    setTabValue(newValue);
  };

  useHandleUpdateUrlWithTabValue(tabValue, 2);

  return (
    <Box
      sx={{
        width: "100%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TabContext value={tabValue}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", position: "relative" }}
        >
          <TabList
            onChange={handleTabChange}
            centered
            indicatorColor="secondary"
            aria-label="tabs for different alarm tables"
          >
            <Tab
              data-testid="ActiveTab"
              label="Active"
              value={AlarmsTabs.ACTIVE}
            />
            <Tab label="Override" value={AlarmsTabs.OVERRIDE} />
            <Tab
              data-testid="HistoryTab"
              label="History"
              value={AlarmsTabs.HISTORY}
            />
          </TabList>
        </Box>
        <CustomTabPanel value={AlarmsTabs.ACTIVE}>
          <ActiveAlarmsTable
            alarms={storeAlarms.active}
            isAllStores
            isLoading={isLoadingAlarms || isLoadingStores}
          />
        </CustomTabPanel>
        <CustomTabPanel value={AlarmsTabs.OVERRIDE}>
          <OverrideAlarmsTable
            alarms={storeAlarms.override}
            isAllStores
            isLoading={isLoadingAlarms || isLoadingStores}
          />
        </CustomTabPanel>
        <CustomTabPanel value={AlarmsTabs.HISTORY}>
          <HistoricalAlarmsTable
            alarms={storeAlarms.historical}
            isAllStores
            isLoading={isLoadingAlarms || isLoadingStores}
          />
        </CustomTabPanel>
      </TabContext>
    </Box>
  );
};

export default AllSiteAlarms;
