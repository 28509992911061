import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  ReactNode,
  MouseEvent,
} from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SiteFilter from "./SiteFilter";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { SelectChangeEvent } from "@mui/material/Select";
import { useAppDispatch } from "../../../../../app/hooks";
import { toggleDrawer } from "../../../../../features/view/viewSlice";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import SiteOperatorFilter from "./SiteOperatorFilter";

type Props = {
  handleStoresDrawerToggle: () => void;
  handleFilterMenuClick: (e: MouseEvent<HTMLButtonElement>) => void;
  handleShowFilter: () => void;
  handleHideFilter: () => void;
  handleSetSortASC: () => void;
  handleSetSortDESC: () => void;
  sortDirection: "ASC" | "DESC" | null;
  isFilterMenuOpen: boolean;

  filterStringDebounced: string;

  filterState: {
    filterBy: string;
    filterString: string;
  };
  menuAnchor: HTMLElement | null;
  handleFilterMenuHide: () => void;
  handleFilterChange: (e: ChangeEvent<HTMLInputElement>) => void;
  showFilter: boolean;
  setFilterBy: (event: SelectChangeEvent<string>, _child: ReactNode) => void;
  setFilterState: Dispatch<
    SetStateAction<{
      filterBy: string;
      filterString: string;
    }>
  >;

  showSiteOperatorFilter: boolean;
  setShowSiteOperatorFilter: Dispatch<SetStateAction<boolean>>;
  operators: string[];
  filteredSiteOperators: string[];
  handleFilterSiteOperator: (operator: string) => void;
  handleFilterAllSiteOperators: (operators: string[]) => void;
  handleUnfilterAllSiteOperators: () => void;
};

const SiteListHeader = ({
  handleStoresDrawerToggle,
  handleFilterMenuClick,
  handleShowFilter,
  handleHideFilter,
  handleSetSortASC,
  handleSetSortDESC,
  sortDirection,
  isFilterMenuOpen,
  filterStringDebounced,
  filterState,
  menuAnchor,
  handleFilterMenuHide,
  handleFilterChange,
  showFilter,
  setFilterBy,
  setFilterState,
  showSiteOperatorFilter,
  setShowSiteOperatorFilter,
  operators,
  filteredSiteOperators,
  handleFilterSiteOperator,
  handleFilterAllSiteOperators,
  handleUnfilterAllSiteOperators,
}: Props) => {
  const dispatch = useAppDispatch();

  const handleToggleDrawer = () => {
    dispatch(toggleDrawer());
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        zIndex: 2,
        paddingBottom: 1,
        rowGap: 1,
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginX: 1,
          marginTop: 1,
          alignItems: "flex-start",
        }}
      >
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleStoresDrawerToggle}
          color="primary"
          variant="contained"
        >
          Main Menu
        </Button>
        <IconButton
          sx={{ padding: 0.5 }}
          title="hide menu"
          onClick={handleToggleDrawer}
        >
          <ChevronLeftOutlinedIcon fontSize="large" />
        </IconButton>
      </Box>

      <Box position="relative">
        <Typography
          variant="h5"
          component="h4"
          textAlign="center"
          position="relative"
          margin="auto"
        >
          Sites{" "}
          {filterStringDebounced.length > 0 && (
            <IconButton
              onClick={handleShowFilter}
              size="small"
              sx={{ position: "absolute" }}
            >
              <FilterAltOutlinedIcon />
            </IconButton>
          )}
        </Typography>

        <IconButton
          id="sites-filter-menu-button"
          onClick={handleFilterMenuClick}
          aria-controls={isFilterMenuOpen ? "sitesMenuMenu" : undefined}
          aria-haspopup="true"
          aria-expanded={isFilterMenuOpen ? "true" : undefined}
          sx={{
            position: "absolute",
            right: (theme) => theme.spacing(1),
            bottom: 0,
            // transform: "translate(-50%, -50%)"
          }}
        >
          <MoreVertOutlinedIcon />
        </IconButton>

        <Menu
          id="sitesMenuMenu"
          anchorEl={menuAnchor}
          open={isFilterMenuOpen}
          onClose={handleFilterMenuHide}
          MenuListProps={{
            "aria-labelledby": "site-filter-menu-button",
          }}
        >
          <MenuItem
            onClick={handleSetSortASC}
            disabled={sortDirection === "ASC"}
          >
            Sort ASC
          </MenuItem>
          <MenuItem
            onClick={handleSetSortDESC}
            disabled={sortDirection === "DESC"}
          >
            Sort DESC
          </MenuItem>
          <MenuItem onClick={handleShowFilter}>Filter</MenuItem>
        </Menu>
        <SiteFilter
          filterState={filterState}
          filterStringDebounced={filterStringDebounced}
          handleFilterChange={handleFilterChange}
          setFilterBy={setFilterBy}
          menuAnchor={menuAnchor}
          handleHideFilter={handleHideFilter}
          showFilter={showFilter}
          setFilterState={setFilterState}
        />
      </Box>

      <SiteOperatorFilter
        operators={operators}
        handleFilterSiteOperator={handleFilterSiteOperator}
        filteredSiteOperators={filteredSiteOperators}
        setShowSiteOperatorFilter={setShowSiteOperatorFilter}
        showSiteOperatorFilter={showSiteOperatorFilter}
        handleFilterAllSiteOperators={handleFilterAllSiteOperators}
        handleUnfilterAllSiteOperators={handleUnfilterAllSiteOperators}
      />
    </Paper>
  );
};

export default SiteListHeader;
