import { useEffect } from 'react';
import { DateTime } from 'luxon';

export default function useHandleUpdateUrlWithTabValue(
  tabValue: string,
  maxPathLength: number,
) {
  useEffect(() => {
    if (tabValue) {
      const currentPath = window.location.pathname;
      const paths = window.location.pathname.split('/');
      const splicedPath = paths.slice(0, -1).join('/');

      if (paths.length > maxPathLength) {
        const tab = paths.slice(Math.max(paths.length - 1, 0));
        // update
        if (tabValue !== tab[0]) {
          window.history.replaceState(
            JSON.stringify(window.history),
            '',
            `${splicedPath}/${tabValue}`,
          );
        }
      } else {
        window.history.replaceState(
          JSON.stringify(window.history),
          '',
          `${currentPath}/${tabValue}`,
        );
      }
    }
  }, [tabValue, maxPathLength]);
}

export function useHandleUpdateUrlWithDateAndTabValue(
  date: DateTime | null,
  tabValue: string,
  maxPathLength: number,
) {
  useEffect(() => {
    if (date && tabValue) {
      const currentPath = window.location.pathname;
      const paths = window.location.pathname.split('/');
      const splicedPath = paths.slice(0, -2).join('/');

      const currentDateString = date.toISODate();

      if (paths.length > maxPathLength) {
        const [reportDateFromURL, reportFromUrl] = paths.slice(
          Math.max(paths.length - 2, 0),
        );
        if (
          reportDateFromURL !== currentDateString ||
          reportFromUrl !== tabValue
        ) {
          window.history.replaceState(
            JSON.stringify(window.history),
            '',
            `${splicedPath}/${currentDateString}/${tabValue}`,
          );
        }
      } else {
        window.history.replaceState(
          JSON.stringify(window.history),
          '',
          `${currentPath}/${currentDateString}/${tabValue}`,
        );
      }
    }
  }, [date, tabValue, maxPathLength]);
}

export function useHandleUpdateUrlWithDateRange(
  startDate: DateTime | null,
  endDate: DateTime | null,
  maxPathLength: number,
) {
  useEffect(() => {
    if (startDate && endDate) {
      const currentPath = window.location.pathname;
      const paths = window.location.pathname.split('/');
      const splicedPath = paths.slice(0, -2).join('/');

      const startDateString = startDate.toUTC().toISO() ?? '';
      const endDateString = endDate.toUTC().toISO() ?? '';
      // need to determine if the dates are already in the url
      // paths.length should be 9 if the dates are in the url
      // if they are in the url update them if they are not the same
      // else place them into the url
      if (paths.length > maxPathLength) {
        const [startDateFromURL, endDateFromURl] = paths.slice(
          Math.max(paths.length - 2, 0),
        );
        if (
          startDateFromURL !== startDateString ||
          endDateFromURl !== endDateString
        ) {
          window.history.replaceState(
            JSON.stringify(window.history),
            '',
            `${splicedPath}/${startDateString}/${endDateString}`,
          );
        }
      } else {
        window.history.replaceState(
          JSON.stringify(window.history),
          '',
          `${currentPath}/${startDateString}/${endDateString}`,
        );
      }
    }
  }, [startDate, endDate, maxPathLength]);
}
