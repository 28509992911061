import SettingsIcon from "@mui/icons-material/Settings";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper, { PaperProps } from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@mui/material/Slide";
import { darken, styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ActiveAlarmsTable from "../../../components/Alarms/ActiveAlarmsTable";
import LeafletMapContainer from "../../../components/OpenStreetMap/LeafletMapContainer";
import Loading from "../../../components/Utility/Loading";
import {
  SiteAlarmType,
  useGetActiveAlarmsForStoreQuery,
} from "../../../features/alarms/alarmsAPI";
import { SiteType, useGetSitesQuery } from "../../../features/sites/sitesAPI";
import {
  deviceAbsentValidApps,
  nonFilteredAlarmStrings,
} from "../SiteAlarms/SiteAlarms";
import SiteController from "./SitePageController";

type Props = {
  siteId: number;
  siteNameAndLocation: string;
  site?: SiteType;
  dashboardMapRect?: DOMRect;
  navigatedFrom: string;
};

const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "1 1 100%",
  columnGap: theme.spacing(1.5),
  rowGap: theme.spacing(1.5),
  width: "100%",
  minHeight: 0,
  maxHeight: "100%",
  position: "relative",
}));

const SiteContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flex: "1 1 100%",
  columnGap: theme.spacing(1.5),
  rowGap: theme.spacing(1.5),
  width: "100%",
  minHeight: 0,
  maxHeight: "100%",
  position: "relative",
  [theme.breakpoints.up("md")]: {
    rowGap: theme.spacing(1.5),
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    rowGap: theme.spacing(1),
  },
}));

const SiteDevicesContainer = styled(Paper)<PaperProps>(({ theme }) => ({
  gridArea: "controllers",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  flexBasis: "25%", // take up 25% of the parents width
  alignItems: "center",
  rowGap: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  minHeight: 0,
  [theme.breakpoints.down("md")]: {
    minHeight: "unset",
    columnGap: theme.spacing(1),
  },
}));

const ControllersBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(1.5),
  columnGap: theme.spacing(1.5),
  overflowY: "auto",
  flex: "1 1 auto",
  minHeight: 0,
  alignItems: "center",
  padding: theme.spacing(1.5),
  backgroundColor: darken(theme.palette.background.paper, 0.1),
  width: "100%",
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
    overflowY: "hidden",
    overflowX: "auto",
    minHeight: "unset",
    padding: theme.spacing(1),
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(1),
  },
}));

const AlarmsAndMapBox = styled(Box)<BoxProps>(({ theme }) => ({
  gridArea: "alarms",
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(1.5),
  flexBasis: "75%",
  // overflowY: 'auto',
  [theme.breakpoints.down("md")]: {
    minHeight: 500,
    rowGap: theme.spacing(1),
  },
}));

const SitePage = ({
  siteId,
  siteNameAndLocation,
  site,
  dashboardMapRect,
  navigatedFrom,
}: Props) => {
  const theme = useTheme();

  const [isReady, setIsReady] = useState(false);

  const isMediumOrLessSCreen = useMediaQuery(theme.breakpoints.down("md"));
  const animationRan = useRef(false);

  const navigatedFromDashboard = useMemo(() => {
    return navigatedFrom === "/";
  }, [navigatedFrom]);

  // still need to maybe "refetch this at some point"
  const { data, isLoading } = useGetSitesQuery(
    { store_list: [siteId] },
    {
      skip: site !== undefined,
    },
  );

  const { data: alarms, isLoading: isLoadingAlarms } =
    useGetActiveAlarmsForStoreQuery(
      { storeId: siteId },
      {
        pollingInterval: 120000,
        refetchOnMountOrArgChange: 30,
      },
    );

  const siteData = useMemo(() => {
    if (site) {
      return site;
    } else if (data && data[0]) {
      return data[0];
    }
  }, [data, site]);
  const isInglesSite = useMemo(() => {
    if (siteData) {
      return siteData.store_operator === "INGLES";
    }
    return false;
  }, [siteData]);

  const siteConfigurationUrl = useMemo(() => {
    const encodedSiteName = encodeURIComponent(siteNameAndLocation);
    return `/site/${siteId}/${encodedSiteName}/settings/`;
  }, [siteId, siteNameAndLocation]);

  const activeAlarms = useMemo(() => {
    // returns an aarray of alarms for each store
    const alarmsActive: SiteAlarmType[] = [];
    if (alarms && alarms[0] && alarms[0].store_num === siteId) {
      for (const alrm of alarms[0].alarms) {
        const alarm = structuredClone(alrm) as SiteAlarmType;
        alarm.siteId = siteId;
        alarm.siteNameAndLocation = siteNameAndLocation;
        alarm.filtered = false;

        if (isInglesSite) {
          alarm.filtered = true;
          // check for filtered vals
          if (
            nonFilteredAlarmStrings.includes(alarm.alarm_text.toLowerCase())
          ) {
            // this is a active alarm to show
            alarm.filtered = false;
          } else if (
            alarm.alarm_text.toLowerCase() === "device absent from network"
          ) {
            for (const val of deviceAbsentValidApps) {
              if (alarm.alarm_source.toLowerCase().includes(val)) {
                alarm.filtered = false;
                break;
              }
            }
          }
        }

        if (alrm.alarm_active === 1 && alrm.service_override_date === null) {
          alarmsActive.push(alarm);
        }
      }
    }
    return alarmsActive;
  }, [alarms, siteId, siteNameAndLocation, isInglesSite]);

  const mapContainer = useCallback(
    async (node: HTMLDivElement | null) => {
      if (node) {
        if (dashboardMapRect && !animationRan.current) {
          const normalSize = node.getBoundingClientRect();

          // if the sidebar is collapsed it will expand when clicking a marker
          // this causes the wrong animation to run.
          // needs to subtract the sidebar width from the dashBoard width/left
          const keyframesFromDashboard = new KeyframeEffect(
            node,
            [
              {
                position: "fixed",
                top: `${dashboardMapRect.top}px`,
                left: `${dashboardMapRect.left}px`,
                right: `${dashboardMapRect.right}px`,
                bottom: `${dashboardMapRect.bottom}px`,
                width: `${dashboardMapRect.width}px`,
                height: `${dashboardMapRect.height}px`,
              },
              {
                position: "fixed",
                top: `${normalSize.top}px`,
                left: `${normalSize.left}px`,
                right: `${normalSize.right}px`,
                bottom: `${normalSize.bottom}px`,
                width: `${normalSize.width}px`,
                height: `${normalSize.height}px`,
              },
            ],
            { easing: theme.transitions.easing.easeInOut, duration: 750 },
          );
          const animationFromDashboard = new Animation(
            keyframesFromDashboard,
            document.timeline,
          );
          await animationFromDashboard.ready;
          setIsReady(true);
          animationFromDashboard.play();
          animationRan.current = true;
        } else {
          setIsReady(true);
        }
      }
    },
    [dashboardMapRect, theme],
  );
  // show placeholders if loading
  return (
    <Container>
      {siteConfigurationUrl !== null && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            component={RouterLink}
            to={siteConfigurationUrl}
            endIcon={<SettingsIcon />}
            aria-label="Navigate to Site Settings"
          >
            Settings
          </Button>
        </Box>
      )}
      <SiteContainer>
        {/* I dont want this transition to run everytime if we are switching between sites */}
        <Slide
          appear={navigatedFromDashboard}
          // enter={navigatedFromDashboard}
          in={isReady}
          timeout={750}
          direction="right"
          easing={theme.transitions.easing.easeInOut}
        >
          <SiteDevicesContainer>
            <Typography
              position="relative"
              variant="h4"
              component="h3"
              textAlign={"center"}
            >
              Controllers
            </Typography>
            <ControllersBox>
              {isLoading ? (
                <Loading />
              ) : siteData && siteData.controllers?.length ? (
                siteData.controllers.map((controller) => {
                  return (
                    <SiteController
                      key={controller.controller_name}
                      controller={controller}
                      siteId={siteId}
                      siteNameAndLocation={siteNameAndLocation}
                      controllersInAlarm={
                        siteData ? siteData.controllers_in_alarm : null
                      }
                    />
                  );
                })
              ) : (
                "No Devices"
              )}
            </ControllersBox>
          </SiteDevicesContainer>
        </Slide>
        <AlarmsAndMapBox>
          <Slide
            appear={navigatedFromDashboard}
            // enter={navigatedFromDashboard}
            in={isReady}
            timeout={750}
            direction={isMediumOrLessSCreen ? "left" : "down"}
            easing={theme.transitions.easing.easeInOut}
          >
            <Box
              sx={{
                flexBasis: "50%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ActiveAlarmsTable
                removeTopMargin
                isLoading={isLoadingAlarms || isLoading}
                alarms={activeAlarms}
              />
            </Box>
          </Slide>
          <Box
            // I'll figure out this transition at some point
            ref={mapContainer}
            sx={{
              flexBasis: "50%",
              display: "flex",
            }}
          >
            {isLoading ? (
              <Skeleton
                width={"100%"}
                height={"100%"}
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: (theme) => theme.shape.borderRadius,
                  minHeight: 300,
                  boxShadow: (theme) => theme?.shadows[8],
                  transform: "none",
                  MozTransform: "none",
                  WebkitTransform: "none",
                }}
              />
            ) : (
              <LeafletMapContainer sites={siteData ? [siteData] : []} />
            )}
          </Box>
        </AlarmsAndMapBox>
      </SiteContainer>
    </Container>
  );
};

export default memo(SitePage);
