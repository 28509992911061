function getBaseApiUrl() {
  if (process.env.JEST_WORKER_ID !== undefined) {
    return "http://10.1.10.100:5000/";
  }

  if (process.env.REACT_APP_USE_PROD_URI === "YES") {
    return "https://leocloud-flask-v2.azurewebsites.net";
  }

  return "https://leocloud-flask-v2.azurewebsites.net";
}

const baseApiUrl = getBaseApiUrl();

export default baseApiUrl;
