import {
  GridColDef,
  GridCellParams,
  GridEventListener,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { useMemo } from "react";
import {
  AllSitesAlarmType,
  SiteAlarmType,
} from "../../features/alarms/alarmsAPI";
import convertIsoToLocalDateTimeString from "../../utils/time";
import DataGrid from "../DataGrid/DataGrid";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import { useNavigate, useLocation } from "react-router-dom";
import { DateTime } from "luxon";

type Props = {
  alarms: SiteAlarmType[] | AllSitesAlarmType[];
  isAllStores?: boolean;
  isLoading: boolean;
};

function isAllSiteAlarmType(obj: unknown): obj is AllSitesAlarmType {
  return obj !== null && typeof obj === "object" && "siteName" in obj;
}

const OverrideAlarmsTable = ({ alarms, isAllStores, isLoading }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const columns: GridColDef[] = useMemo(() => {
    if (isAllStores) {
      return [
        {
          headerName: "Date/Time",
          field: "alarm_date",
          flex: 0.5,
          minWidth: 200,
          align: "left",
          headerAlign: "left",
          valueFormatter: (params: GridValueFormatterParams<string>) => {
            return convertIsoToLocalDateTimeString(params.value);
          },
        },
        {
          headerName: "Site Name",
          field: "siteName",
          flex: 0.5,
        },
        {
          headerName: "Controller",
          field: "controller_name",
          flex: 0.5,
          minWidth: 200,
        },
        {
          renderHeader: () => (
            <strong>
              {"Source"}
              &nbsp;
              <span>
                <QueryStatsRoundedIcon />
              </span>
            </strong>
          ),
          field: "alarm_source",
          flex: 1,
          minWidth: 200,
          cellClassName: (
            props: GridCellParams<string, SiteAlarmType | AllSitesAlarmType>,
          ) => {
            if (
              props.row.application === null ||
              props.row.application === "0"
            ) {
              return "";
            }
            return "active-alarm_source-cell";
          },
        },
        {
          headerName: "Description",
          field: "alarm_text",
          flex: 1,
          minWidth: 200,
        },
        {
          headerName: "Override Until",
          field: "service_override_date",
          flex: 0.5,
          minWidth: 200,
          valueFormatter: (params: GridValueFormatterParams<string>) => {
            return convertIsoToLocalDateTimeString(params.value);
          },
        },
      ];
    } else {
      return [
        {
          headerName: "Date/Time",
          field: "alarm_date",
          flex: 0.5,
          minWidth: 200,
          align: "left",
          headerAlign: "left",
          valueFormatter: (params: GridValueFormatterParams<string>) => {
            return convertIsoToLocalDateTimeString(params.value);
          },
        },
        {
          headerName: "Controller",
          field: "controller_name",
          flex: 0.5,
          minWidth: 200,
        },
        {
          renderHeader: () => (
            <strong>
              {"Source"}
              &nbsp;
              <span>
                <QueryStatsRoundedIcon />
              </span>
            </strong>
          ),
          field: "alarm_source",
          flex: 1,
          minWidth: 200,
          cellClassName: (
            props: GridCellParams<string, SiteAlarmType | AllSitesAlarmType>,
          ) => {
            if (
              props.row.application === null ||
              props.row.application === "0"
            ) {
              return "";
            }
            return "active-alarm_source-cell";
          },
        },
        {
          headerName: "Description",
          field: "alarm_text",
          flex: 1,
          minWidth: 200,
        },
        {
          headerName: "Override Until",
          field: "service_override_date",
          flex: 0.5,
          minWidth: 200,
          valueFormatter: (params: GridValueFormatterParams<string>) => {
            return convertIsoToLocalDateTimeString(params.value);
          },
        },
      ];
    }
  }, [isAllStores]);

  const tableData = useMemo(() => {
    if (alarms) {
      return alarms.map((d, i) => ({ ...d, id: i }));
    }

    return [];
  }, [alarms]);

  const fillInGraphingUrl = (
    siteId: number,
    encodedSiteNameAndLocation: string,
    encodedControllerName: string,
    encodedApplication: string,
  ) => {
    return `/site/${siteId}/${encodedSiteNameAndLocation}/controller/${encodedControllerName}/graph/${encodedApplication}`;
  };

  const getPropertiesBasedOnAlarmType = (
    row: SiteAlarmType | AllSitesAlarmType,
    siteId: number,
    encodedControllerName: string,
    encodedApplication: string,
  ) => {
    if (isAllSiteAlarmType(row)) {
      const encodedSiteNameAndLocation = encodeURIComponent(
        `${row.siteName} - ${row.siteLocation}`,
      );
      return fillInGraphingUrl(
        siteId,
        encodedSiteNameAndLocation,
        encodedControllerName,
        encodedApplication,
      );
    } else {
      const encodedSiteNameAndLocation = encodeURIComponent(
        row.siteNameAndLocation,
      );
      return fillInGraphingUrl(
        siteId,
        encodedSiteNameAndLocation,
        encodedControllerName,
        encodedApplication,
      );
    }
  };

  const buildGraphingUrl = (
    value: string,
    row: SiteAlarmType | AllSitesAlarmType,
  ) => {
    const encodedControllerName = encodeURIComponent(row.controller_name);
    const siteId = row.siteId;
    if (value.includes(":")) {
      const [application] = value.split(":");
      const encodedApplication = encodeURIComponent(application);
      return getPropertiesBasedOnAlarmType(
        row,
        siteId,
        encodedControllerName,
        encodedApplication,
      );
    } else {
      const application = value;
      const encodedApplication = encodeURIComponent(application);
      return getPropertiesBasedOnAlarmType(
        row,
        siteId,
        encodedControllerName,
        encodedApplication,
      );
    }
  };

  const handleCellClick: GridEventListener<"cellClick"> = (
    params: GridCellParams<string, SiteAlarmType | AllSitesAlarmType>,
    _event,
    _details,
  ) => {
    if (params.field === "alarm_source") {
      // they clicked the alarm_source cell
      const { value, row } = params;

      if ((row.application !== null || row.application !== "0") && value) {
        const url = buildGraphingUrl(value, row);

        const startDate = DateTime.fromISO(row.alarm_date)
          .minus({ hours: 1 })
          .toUTC()
          .toISO();
        const endDate = DateTime.now().toUTC().toISO();

        navigate(url, {
          state: {
            graphRequestDates: {
              startDate,
              endDate,
            },
            from: location.pathname,
          },
        });
      }
    }
  };

  return (
    <DataGrid
      rows={tableData}
      columns={columns}
      loading={isLoading}
      getRowClassName={(params) => {
        if (params.indexRelativeToCurrentPage % 2 === 0) {
          return "blue-row";
        }

        return "blue-row--odd";
      }}
      autoPageSize
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "alarm_date",
              sort: "desc",
            },
          ],
        },
      }}
      onCellClick={handleCellClick}
      quickFilter
    />
  );
};

export default OverrideAlarmsTable;
